@charset "utf-8";

/* UDÁLOSTI */

/* event list */

.event-list {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 1rem;
}

@media (--SMALLSCREEN) {
  
  .event-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (--MEDIUMSCREEN) {
  
  .event-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (--WIDESCREEN) {
  
  .event-list {
  }
}

/* event list end */

/* event list messages */

.event-list-messages {
  grid-column: 1/-1;
}

/* event list messages end */

/* event item */

.event-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-decoration: none;
  
  &__headline {
    margin: 0rem 0rem 0rem 0rem;
  }
  
  &__picture {
  }
  
  &__date {
    margin: 0rem 0rem 0rem 0rem;
  }
  
  &__desc {
    max-height: 4.35em;
    overflow: hidden;
    margin: 0rem 0rem 0rem 0rem;
  }
  
  &__button {
    margin: auto 0 0 0;
    width: 100%;
  }
}

@media (--SMALLSCREEN) {
  
  .event-item {
    
    &__desc {
      max-height: none;
      height: 4.35em;
    }
  }
}

@media (--MEDIUMSCREEN) {
  
  .event-item {
  }
}

@media (--WIDESCREEN) {
  
  .event-item {
  }
}

/* event item end */

/* news detail info */

.event-detail {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.25rem 0.25rem;
  font-weight: bold;
  
  &__date {
  }
}

@media (--SMALLSCREEN) {
  
  .event-detail-info {
  }
}

@media (--MEDIUMSCREEN) {
  
  .event-detail-info {
  }
}

@media (--WIDESCREEN) {
  
  .event-detail-info {
  }
}

/* event detail info end */

/* event registration */

.event-registration {
  margin: 0rem auto 0rem auto;
  max-width: 30rem;
  
  &__headline {
  }
  
  &__form {
  }
  
  &__button {
    margin: 0rem auto 0rem 0rem;
  }
}

@media (--SMALLSCREEN) {
  
  .event-registration {
  }
}

@media (--MEDIUMSCREEN) {
  
  .event-registration {
  }
}

@media (--WIDESCREEN) {
  
  .event-registration {
  }
}

/* event registration end */