@charset "utf-8";

/* UŽIVATELSKÝ ÚČET PŘED PŘIHLÁŠENÍM */

/* width small user account page */

.width-small-user-account-page {
  max-width: 32rem;
  margin: 0 auto 0rem auto;
}

/* width small user account page end */

/* user account page */

.user-account-page {
}

@media (--SMALLSCREEN) {
  
  .user-account-page {
  }
}

@media (--MEDIUMSCREEN) {
  
  .user-account-page {
  }
}

@media (--WIDESCREEN) {
  
  .user-account-page {
  }
}

/* user account page end */

/* user account block */

.user-account-block {
  
  &__content {
  }
}

@media (--SMALLSCREEN) {
  
  .user-account-block {
  }
}

@media (--MEDIUMSCREEN) {
  
  .user-account-block {
  }
}

@media (--WIDESCREEN) {
  
  .user-account-block {
  }
}

/* user account block end */

/* user account form */

.user-account-form {
}

@media (--SMALLSCREEN) {
  
  .user-account-form {
  }
}

@media (--MEDIUMSCREEN) {
  
  .user-account-form {
  }
}

@media (--WIDESCREEN) {
  
  .user-account-form {
  }
}

/* user account form end */

/* user account handles */

.user-account-handles {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-items: center;
  gap: 1rem;
  
  &__block {
    width: 100%;
    display: flex;
    align-items: center;
    
    &--1 {
       gap: 1rem;
    }
    
    &--2 {
       gap: 0.75rem;
      
      ^^&--order-reverse & {
         order: -1;
      }
    }
  }
  
  &__button {
    width: 100%;
    margin: 0rem 0rem 0rem 0rem;
  }
  
  &__link {
  }
}

@media (--SMALLSCREEN) {
  
  .user-account-handles {
    grid-template-columns: minmax(0, 1fr) auto;
    
    &__block {
      
      &--2 {
         order: -1;
      }
    }
  
    &__button {
      width: auto;
      margin-left: auto;
    }
  }
}

@media (--MEDIUMSCREEN) {
  
  .user-account-handles {
  }
}

@media (--WIDESCREEN) {
  
  .user-account-handles {
  }
}

/* user account handles end */

/* user account popup */

.user-account-popup {
}

@media (--SMALLSCREEN) {
  
  .user-account-popup {
  }
}

@media (--MEDIUMSCREEN) {
  
  .user-account-popup {
  }
}

@media (--WIDESCREEN) {
  
  .user-account-popup {
  }
}

/* user account popup end */