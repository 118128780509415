@charset "utf-8";

/* FORMULÁŘE */

/* form parts */

form {
  margin: 0rem;
  padding: 0rem;
}

label {
  margin: 0rem 0rem 0rem 0rem;
  padding: 0rem 0rem 0rem 0rem;
  font-size: 1rem;
  line-height: var(--line-height-basic);
}

button,
[type=submit]{
  border: 0rem;
  cursor: pointer;
  font-family: inherit;
  background: none;
  font-size: 1rem;
  appearance: none;
  margin: 0rem;
  padding: 0rem;
}

@media (--SMALLSCREEN) {
}

@media (--MEDIUMSCREEN) {
}

@media (--WIDESCREEN) {
}

/* form parts end */

/* input, select, textarea */

:--form-element-basic {
  flex: none;
  width: 100%;
  min-width: 0;
  height: 3rem;
  line-height: normal;
  font-size: var(--font-size-basic);
  border-radius: 0rem;
  margin: 0rem 0rem 0rem 0rem;
  padding: 0rem 0.75rem;
  border: 1px solid var(--border-color-basic);
  background: url(../images/style/icons/loader.svg) left -100rem / 6.25rem auto no-repeat #fff;
  font-family: inherit;
  color: inherit;
  box-shadow: none;
  animation: 5s infinite linear paused;
  
  &:focus {
    box-shadow:0 0 0.25rem #0003;
  }
  
  &.is-valid {
    border-color: #A8DEA6;
  }
  
  &.is-invalid{
    border-color: #b71616;
  }
  
  &::placeholder {
    color: inherit;
    opacity: 0.5;
  }
  
  &::-ms-clear {
    display: none;
  }
  
  &.is-validating {
    animation-play-state: running;
    pointer-events: none;
    
    &:not(select) {
      animation-name: form-item-basic-loader;
      background-repeat: repeat;
    }
  }
}

select {
  appearance: none;
  padding-right: 2.5rem;
  background-image:
    url(../images/style/icons/selectbox.svg),
    url(../images/style/icons/loader.svg);
  background-position: calc(100% - 0.75rem) center, left -100rem;
  background-size: 0.875rem auto, 6.25rem auto;
  
  &::-ms-expand {
    display: none;
  }
  
  &.is-validating {
    animation-name: form-item-select-loader;
    background-repeat: no-repeat, repeat;
  }
}

textarea{
  width: 100%;
  height: 8rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  vertical-align: top;
}

@media (--SMALLSCREEN) {
  
  :--form-element-basic {
  }
}

@media (--MEDIUMSCREEN) {
  
  :--form-element-basic {
  }
}

@media (--WIDESCREEN) {
  
  :--form-element-basic {
  }
}

/* input, select, textarea end */

/* checkbox, radio */

:--form-element-choice {
  width: 1.5rem;
  height: 1.5rem;
  flex: none;
  border: 1px solid var(--border-color-basic);
  margin: 0rem 0rem 0rem 0rem;
  padding: 0rem;
  box-shadow: none;
  display: inline-block;
  vertical-align: top;
  appearance: none;
  background: center -10rem / 0.875rem auto no-repeat #fff;
  
  &:checked {
    background-position: center center;
  }
}

[type="checkbox"] {
    background-image: url(../images/style/icons/checkbox.svg);
    border-radius: 0rem;
}

[type="radio"] {
    background-image: url(../images/style/icons/radio.svg);
    border-radius: 50%;
}

@media (--SMALLSCREEN) {
  
  :--form-element-choice {
  }
}

@media (--MEDIUMSCREEN) {
  
  :--form-element-choice {
  }
}

@media (--WIDESCREEN) {
  
  :--form-element-choice {
  }
}

/* checkbox, radio end */

/* form list */

.form-list {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
}

@media (--SMALLSCREEN) {
  
  .form-list {
  }
}

@media (--MEDIUMSCREEN) {
  
  .form-list {
  }
}

@media (--WIDESCREEN) {
  
  .form-list {
  }
}

/* form list end */

/* form item */

.form-item {
  
  &__stable {
  }
  
  &__vars {
    font-size: 0.75rem;
    
    > * {
      padding-top: 0.25rem;
      
      &:empty {
        display: none;
      }
    }
  }
  
  &__desc {
    font-size: 1rem;
  }
  
  &__additional {
    color: #777;
  }
  
  &__errors {
  }
  
  &__error {
    display: block;
    color: #b71616;
  }
  
  /* input, select, textarea */
  
  &--basic {
    
    ^&__stable {
      position: relative;
    }
  
    ^&__desc {
      position: absolute;
      top: 50%;
      left: 0.438rem;
      background: #fff;
      padding: 0 0.375rem;
      transition: 0.2s;
      transform: translateY(-50%);
    }
    
    textarea ~ ^&__desc {
      top: 1.25rem;
    }
    
    &.is-filled ^&__desc,
    :--form-element-basic:focus ~ ^&__desc {
      font-size: 0.75rem;
      top: 0rem;
    }
    
    :--form-element-basic:required ~ ^&__desc:after {
      content: '*';
    }
  }
  
  /* checkbox, radio */
  
  &--choice {
    display: inline-block;
    vertical-align: top;
    
    ^&__stable {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }
    
    ^&__desc {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0rem;
    }
    
    ^&__row {
      /* používá se pouze v případě vícera inputů */
      width: 100%;
      display: flex;
      align-items: center;
      gap: 0.75rem;
      margin-bottom: 0.5rem;
      
      &:last-child {
        margin-bottom: 0rem;
      }
    }
    
    ^&__vars {
      padding-left: 2.25rem;
    }
  }
}

@media (--SMALLSCREEN) {
  
  .form-item {
  }
}

@media (--MEDIUMSCREEN) {
  
  .form-item {
  }
}

@media (--WIDESCREEN) {
  
  .form-item {
  }
}

/* form item end */

/* animation form item basic/select loader */

@keyframes form-item-basic-loader {
  0% {background-position: 0rem top;}
  50% {background-position: 10rem top;}
  100% {background-position: 20rem top;}
}

@keyframes form-item-select-loader {
  0% {background-position: calc(100% - 0.75rem) center, 0rem top;}
  50% {background-position: calc(100% - 0.75rem) center, 10rem top;}
  100% {background-position: calc(100% - 0.75rem) center, 20rem top;}
}

/* animation form item basic/select loader end */