@charset "utf-8";

/* NOVINKY */

/* news arrangement */

.news-arrangement {
}

@media (--SMALLSCREEN) {
  
  .news-arrangement {
  }
}

@media (--MEDIUMSCREEN) {
  
  .news-arrangement {
  }
}

@media (--WIDESCREEN) {
  
  .news-arrangement {
  }
}

/* news arrangement end */

/* news arrangement list */

.news-arrangement-list {
  
  &__content {
    width: 100%;
    display: flex;
    gap: 0.125rem 0.75rem;
    align-items: flex-start;
  }
}

@media (--SMALLSCREEN) {
  
  .news-arrangement-list {
  }
}

@media (--MEDIUMSCREEN) {
  
  .news-arrangement-list {
  }
}

@media (--WIDESCREEN) {
  
  .news-arrangement-list {
  }
}

/* news arrangement list end */

/* news arrangement item end */

.news-arrangement-item {
  flex: none;
  font-size: 0.875rem;
  cursor: pointer;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
  
  &.is-active {
    cursor: default;
    font-weight: bold;
    pointer-events: none;
  }
  
  &--headline {
    cursor: default;
  }
}

@media (--SMALLSCREEN) {
  
  .news-arrangement-item {
  }
}

@media (--MEDIUMSCREEN) {
  
  .news-arrangement-item {
  }
}

@media (--WIDESCREEN) {
  
  .news-arrangement-item {
  }
}

/* news arrangement item end */

/* news category list */

.news-category-list {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 1rem;
}

@media (--SMALLSCREEN) {
  
  .news-category-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (--MEDIUMSCREEN) {
  
  .news-category-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (--WIDESCREEN) {
  
  .news-category-list {
  }
}

/* news category list end */

/* news category item */

.news-category-item {
  position: relative;
  border: 1px solid #000;
  
  &__picture {
  }
  
  &__headline {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    margin: 0rem 0rem 0rem 0rem;
    background: #fff9;
    padding: 0.75rem var(--gap-page);
  }
}

@media (--SMALLSCREEN) {
  
  .news-category-item {
  }
}

@media (--MEDIUMSCREEN) {
  
  .news-category-item {
  
    &__headline {
      padding: 1.25rem 1.5rem;
    }
  }
}

@media (--WIDESCREEN) {
  
  news-category-item {
  }
}

/* news category item end */

/* news list */

.news-list {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 1rem;
}

@media (--SMALLSCREEN) {
  
  .news-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (--MEDIUMSCREEN) {
  
  .news-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (--WIDESCREEN) {
  
  .news-list {
  }
}

/* news list end */

/* news list messages */

.news-list-messages {
  grid-column: 1/-1;
}

/* news list messages end */

/* news item */

.news-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-decoration: none;
  
  &__headline {
    margin: 0rem 0rem 0rem 0rem;
  }
  
  &__picture {
  }
  
  &__date {
    margin: 0rem 0rem 0rem 0rem;
  }
  
  &__desc {
    max-height: 4.35em;
    overflow: hidden;
    margin: 0rem 0rem 0rem 0rem;
  }
  
  &__button {
    margin: auto 0 0 0;
    width: 100%;
  }
}

@media (--SMALLSCREEN) {
  
  .news-item {
  
    &__desc {
      max-height: none;
      height: 4.35em;
    }
  }
}

@media (--MEDIUMSCREEN) {
  
  .news-item {
  }
}

@media (--WIDESCREEN) {
  
  .news-item {
  }
}

/* news item end */

/* news detail info */

.news-detail-info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.25rem 0.25rem;
  font-weight: bold;
  
  > * {
    
    &:after {
      content: ',';
      font-weight: normal;
    }
    
    &:last-child {
      
      &:after {
        content: '';
      }
    }
  }
  
  &__date {
  }
  
  &__author {
  }
  
  &__read {
  }
  
  &__read-desc {
  }
  
  &__read-value {
  }
}

@media (--SMALLSCREEN) {
  
  .news-detail-info {
  }
}

@media (--MEDIUMSCREEN) {
  
  .news-detail-info {
  }
}

@media (--WIDESCREEN) {
  
  .news-detail-info {
  }
}

/* news detail info end */

/* news related */

.news-related {
  
  &__headline {
  }
}

@media (--SMALLSCREEN) {
  
  .news-related {
  }
}

@media (--MEDIUMSCREEN) {
  
  .news-related {
  }
}

@media (--WIDESCREEN) {
  
  .news-related {
  }
}

/* news related end */

/* news topped */

.news-topped {
  
  &__list {
    margin-bottom: calc(var(--gap-block) / 2);
  }
  
  &__continue {
    text-align: center;
  }
  
  &__button {
    margin: 0;
  }
}

@media (--SMALLSCREEN) {
  
  .news-topped {
  }
}

@media (--MEDIUMSCREEN) {
  
  .news-topped {
  }
}

@media (--WIDESCREEN) {
  
  .news-topped {
  }
}

/* news topped end */