@charset "utf-8";

/* PŘEPÍNAČ MEZI FRONTENDEM A ADMINISTRACÍ */

.admin-menu {
  position:fixed;
  background: linear-gradient(to bottom, rgba(36,89,121,0.95) 0%,rgba(18,65,89,0.95) 100%);
  box-shadow:0 2px 1px rgba(0,0,0,0.5);
  border-bottom: 1px solid #235878;
  border-left: 1px solid #235878;
  padding:5px;
  border-bottom-left-radius:5px;
  right:0;
  z-index:1000;
  display: none;
}

.admin-menu-icon-medium {
  height:36px;
  line-height:36px;
  padding:0 36px 0 0;
  display:block;
  float:left;
  margin:0.1em;
  font-size:1em;
  border:none;
  cursor: pointer;
  text-decoration:none;
  color:#002938;
}

.admin-menu-icon-medium:hover                        {background-position:center right; color:#1d85c4;}
.admin-menu-icon-medium:active                       {background-position:bottom right; color:#1d85c4;}
.admin-menu-icon-medium span                         {margin:0 1em 0 1em;} /* odsazeni pripadneho textu uvnitr tlacitka */
.admin-menu-icon-medium span.hidden                  {display:none;} /* Pokud se ma zobrazovat pouze ikonka */

.admin-menu-icon-medium.admin-menu-icon-switch {
  background: url(../images/style/icons/admin-switch.png) top right no-repeat;
}
.admin-menu-icon-medium.admin-menu-icon-logout {
  background:url(../images/style/icons/admin-logout.png) top right no-repeat;
}

@media (min-width: 100rem) {
  
  nav.admin-menu {
    display: block;
  }
}