@charset "utf-8";

/* KOŠÍK/OBJEDNÁVKA */

/* order section */

.order-section {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 1rem;
  
  &__headline {
    width: 100%;
    grid-column: 1/-1;
    font-size: 2rem;
    margin: 0rem 0rem 0rem 0rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--border-color-basic);
  }
  
  &__block {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 1rem;
    
    &--full {
       grid-column: 1/-1;
    }
  
    &--optional {
      margin-bottom: auto;
    }
  }
}

@media (--SMALLSCREEN) {
  
  .order-section {
  }
}

@media (--MEDIUMSCREEN) {
  
  .order-section {
  }
}

@media (--WIDESCREEN) {
  
  .order-section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

/* order section end */

/* order block */

.order-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0rem;
  
  &--invoicing {
    margin-bottom: auto;
  }
  
  &__headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 3rem;
    background: #efefef;
    margin: 0rem 0rem 0rem 0rem;
    padding: 0.875rem 1.25rem;
    font-size: 1rem;
  }
  
  &__content {
    flex-grow: 1;
    background: #f8f8f8;
    padding: 1.25rem 1.25rem;
  
    ^&--basket & {
      padding: 0rem;
      background: none;
    }
    
    ^&--select-country & {
      padding: 0rem;
      background: none;
    }
  
    ^&--basket-summary & {
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
      background: #efefef;
    }
  
    ^&--free-gift & {
      padding: 0rem;
      background: none;
    }
  
    ^&--delivery-summary & {
      background: #efefef;
    }
  
    ^&--handles & {
      padding: 0rem;
      background: none;
    }
  }
  
  &__button {
    margin: 0rem auto 0rem 0rem;
  }
}

@media (--SMALLSCREEN) {
  
  .order-block {
  }
}

@media (--MEDIUMSCREEN) {
  
  .order-block {
  
    &--select-country {
      padding-right: calc(50% + 0.5rem);
    }
  
    &__headline {
      min-height: 4rem;
    }
  }
}

@media (--WIDESCREEN) {
  
  .order-block {
  }
}

/* order block end */

/* order messages */

.order-messages {
}

@media (--SMALLSCREEN) {
  
  .order-messages {
  }
}

@media (--MEDIUMSCREEN) {
  
  .order-messages {
  }
}

@media (--WIDESCREEN) {
  
  .order-messages {
  }
}

/* order messages end */

/* basket */

.basket {
}

@media (--SMALLSCREEN) {
  
  .basket {
  }
}

@media (--MEDIUMSCREEN) {
  
  .basket {
  }
}

@media (--WIDESCREEN) {
  
  .basket {
  }
}

/* basket end */

/* basket item */

.basket-item {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.625rem 0.75rem;
  border: 0rem solid var(--border-color-basic);
  border-bottom-width: 1px;
  padding: 1rem 0rem;
  
  &__block {
    width: calc(50% - 0.5rem);
    flex: none;
    
    &--picture {
      width: 5.5rem;
      flex: none;
      order: 10;
    }
  
    &--name-wrapper {
      flex-grow: 1;
      order: 20;
    }
  
    &--pieces-picker-wrapper {
      width: 6.75rem;
      flex: none;
      order: 50;
    }
    
    &--unit-price {
      white-space: nowrap;
      order: 30;
    }
  
    &--price {
      font-weight: bold;
      white-space: nowrap;
      margin-left: auto;
      text-align: right;
      order: 40;
    }
  
    &--remove-wrapper {
      width: auto;
      flex: none;
      margin-left: auto;
      order: 60;
    }
  }
  
  &__picture {
  }
  
  &__name {
    font-weight: bold;
  }
  
  &__delivery-desc {
  }
  
  &__delivery-value {
  }
  
  &__discount-desc {
  }
  
  &__discount-value {
  }
  
  &__remove {
    width: 2.25rem;
    height: 2.25rem;
    display: block;
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    background: #e0e0e0;
    margin: 0rem 0rem 0rem auto;
    
    &:hover {
      background: #cfcfcf;
    }
    
    &:before,
    &:after {
      width: 0.75rem;
      height: 0.125rem;
      display: block;
      content: '';
      background: #000;
      position: absolute;
      left: calc(50% - 0.375rem);
      top: calc(50% - 1px);
      transform: rotate(45deg);
    }
    
    &:after {
      transform:rotate(135deg);
    }
  }
}

@media (--SMALLSCREEN) {
  
  .basket-item {
  }
}

@media (--MEDIUMSCREEN) {
  
  .basket-item {
  }
}

@media (--WIDESCREEN) {
  
  .basket-item {
    display: grid;
    grid-template-columns: 5.5rem 40% 6.75rem 12% 12% auto;
    
    &__block {
      width: auto;
  
      &--pieces-picker-wrapper {
        order: 25;
        text-align: center;
      }
  
      &--unit-price {
        text-align: right;
      }
  
      &--remove-wrapper {
        order: 60;
      }
    }
  }
}

/* basket end */

/* basket summary */

.basket-summary {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  font-size: 1.25rem;
  font-weight: bold;
  
  &__desc {
  }
  
  &__value {
    text-align: right;
  }
  
  &__price {
    white-space: nowrap;
  }
  
  &__vatin {
    display: block;
    font-size: 0.688em;
    font-weight: normal;
  }
}

@media (--SMALLSCREEN) {
  
  .basket-summary {
  }
}

@media (--MEDIUMSCREEN) {
  
  .basket-summary {
  }
}

@media (--WIDESCREEN) {
  
  .basket-summary {
  }
}

/* basket summary end */

/* order discount */

.order-discount {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-items: center;
  gap: 0.75rem;
  
  &__block {
  }
  
  &__handles {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
  }
  
  &__form-item {
    flex-grow: 1;
  }
  
  &__button {
    min-width: 0rem;
    margin: 0rem 0rem 0rem 0rem;
  }
}

@media (--SMALLSCREEN) {
  
  .order-discount {
  }
}

@media (--MEDIUMSCREEN) {
  
  .order-discount {
  }
}

@media (--WIDESCREEN) {
  
  .order-discount {
    grid-template-columns: minmax(0, 1fr) auto;
  
    &__form-item {
      width: 15rem;
    }
  }
}

/* order discount end */

/* order gift */

.order-gift {
  
  &__content {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 2rem;
  }
  
  &__button {
    margin: 0rem auto 0rem 0rem;
  }
}

@media (--SMALLSCREEN) {
  
  .order-gift {
  }
}

@media (--MEDIUMSCREEN) {
  
  .order-gift {
  }
}

@media (--WIDESCREEN) {
  
  .order-gift {
  }
}

/* order gift end */

/* order gift list */

.order-gift-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
}

@media (--SMALLSCREEN) {
  
  .order-gift-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (--MEDIUMSCREEN) {
  
  .order-gift-list {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media (--WIDESCREEN) {
  
  .order-gift-list {
  }
}

/* order gift list end */

/* order gift item */

.order-gift-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  
  &.is-active {
  }
  
  &__picture {
    margin: auto 0rem 0rem 0rem;
  }
  
  &__headline {
    font-size: 1rem;
    margin: 0rem 0rem 0rem 0rem;
  }
  
  &__button {
    width: 100%;
    min-width: 0rem;
    margin: 0rem 0rem 0rem 0rem;
    
    &--blind {
      visibility: hidden;
    }
  }
  
  &__price {
    font-weight: bold;
    font-size: 0.875rem;
  }
}

@media (--SMALLSCREEN) {
  
  .order-gift-item {
  }
}

@media (--MEDIUMSCREEN) {
  
  .order-gift-item {
  }
}

@media (--WIDESCREEN) {
  
  .order-gift-item {
  }
}

/* order gift item end */

/* delivery, payment fake radios */

.order-block--delivery input,
.order-block--payment input {
  border-radius: 50%;
  background-image: url(../images/style/icons/radio.svg);
}

@media (--SMALLSCREEN) {
  
  .order-block--delivery input,
  .order-block--payment input {
  }
}

@media (--MEDIUMSCREEN) {
  
  .order-block--delivery input,
  .order-block--payment input {
  }
}

@media (--WIDESCREEN) {
  
  .order-block--delivery input,
  .order-block--payment input {
  }
}

/* delivery, payment fake radios end */

/* delivery, payment info */

.order-delpay-info {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  
  &__basic {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    flex-grow: 1;
  }
  
  &__text {
    font-size: 0.813rem;
  }
  
  &__price {
     flex: none;
  }
}

@media (--SMALLSCREEN) {
  
  .order-delpay-info {
  }
}

@media (--MEDIUMSCREEN) {
  
  .order-delpay-info {
  }
}

@media (--WIDESCREEN) {
  
  .order-delpay-info {
  }
}

/* delivery, payment info end */

/* order choose branch */

.order-choose-branch {
  
  &__button {
    background: #000;
    color: #fff;
    display: inline-block;
    vertical-align: top;
    font-size: 0.813rem;
    padding: 0.25rem 0.5rem;
  }
  
  &__info {
    font-size: 0.813rem;
  }
}

@media (--SMALLSCREEN) {
  
  .order-choose-branch {
  }
}

@media (--MEDIUMSCREEN) {
  
  .order-choose-branch {
  }
}

@media (--WIDESCREEN) {
  
  .order-choose-branch {
  }
}

/* order choose branch end */

/* delivery pickup */

.delivery-pickup {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  
  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  &__button {
    margin: 0rem 0rem 0rem 0rem;
  }
}

@media (--SMALLSCREEN) {
  
  .delivery-pickup {
  }
}

@media (--MEDIUMSCREEN) {
  
  .delivery-pickup {
  }
}

@media (--WIDESCREEN) {
  
  .delivery-pickup {
  }
}

/* delivery pickup end */

/* delivery pickup places */

.delivery-pickup-places {
  width: 100%;
  display: grid;
  gap: 0.75rem;
  grid-template-columns: minmax(0, 1fr);
}

@media (--SMALLSCREEN) {
  
  .delivery-pickup-places {
  }
}

@media (--MEDIUMSCREEN) {
  
  .delivery-pickup-places {
    max-height: 21rem;
    overflow: auto;
  }
}

@media (--WIDESCREEN) {
  
  .delivery-pickup-places {
  }
}

/* delivery pickup places end */

/* delivery pickup place */

.delivery-pickup-place {
  border: 1px solid var(--border-color-basic);
  padding: 0.75rem;
  transition: 0.3s;
  cursor: pointer;
  
  &:hover,
  &.is-active {
    background: rgba(232, 232, 232, 0.5);
  }
  
  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  &__headline {
    margin: 0rem 0rem 0rem 0rem;
    font-size: 1rem;
  }
  
  &__row {
  }
}

@media (--SMALLSCREEN) {
  
  .delivery-pickup-place {
  }
}

@media (--MEDIUMSCREEN) {
  
  .delivery-pickup-place {
  }
}

@media (--WIDESCREEN) {
  
  .delivery-pickup-place {
  }
}

/* delivery pickup place end */

/* delivery summary */

.delivery-summary {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  font-size: 1.25rem;
  font-weight: bold;
  
  &__desc {
  }
  
  &__value {
  }
  
  &__price {
    white-space: nowrap;
  }
}

@media (--SMALLSCREEN) {
  
  .delivery-summary {
  }
}

@media (--MEDIUMSCREEN) {
  
  .delivery-summary {
  }
}

@media (--WIDESCREEN) {
  
  .delivery-summary {
  }
}

/* delivery summary end */

/* basket empty */

.basket-empty {
}

@media (--SMALLSCREEN) {
  
  .basket-empty {
  }
}

@media (--MEDIUMSCREEN) {
  
  .basket-empty {
  }
}

@media (--WIDESCREEN) {
  
  .basket-empty {
  }
}

/* basket empty end */

/* order created */

.order-created {
  max-width: 41.25rem;
  margin: var(--gap-block) auto 0rem auto;
  text-align: center;
  
  > *:last-child {
    margin-bottom: 0rem;
  }
  
  &__headline {
    padding-top: 1.625em;
    color: #37C628;
    background: url(../images/style/icons/thankyoupage.svg) center top / 1.375em auto no-repeat;
  }
  
  &__desc {
    font-weight: bold;
  }
  
  &__messages {
  }
  
  &__text {
  }
  
  &__text-row {
    display: block;
  }
  
  &__link {
  }
  
  &__payment {
  }
  
  &__button {
    margin: 0rem 0rem 0rem 0rem;
  }
}

@media (--SMALLSCREEN) {
  
  .order-created {
  }
}

@media (--MEDIUMSCREEN) {
  
  .order-created {
  }
}

@media (--WIDESCREEN) {
  
  .order-created {
  }
}

/* order created end */

/* order status section */

.order-status-section {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: var(--gap-block);
  
  &__block {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 1rem;
    
    &--full {
      grid-column: 1/-1;
    }
  }
}

@media (--SMALLSCREEN) {
  
  .order-status-section {
  }
}

@media (--MEDIUMSCREEN) {
  
  .order-status-section {
  }
}

@media (--WIDESCREEN) {
  
  .order-status-section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

/* order status section end */

/* order status block */

.order-status-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0rem;
  
  &__headline {
  }
  
  &__content {
    flex-grow: 1;
  }
}

@media (--SMALLSCREEN) {
  
  .order-status-block {
  }
}

@media (--MEDIUMSCREEN) {
  
  .order-status-block {
  }
}

@media (--WIDESCREEN) {
  
  .order-status-block {
  }
}

/* order status block end */

/* order status table */

.order-status-table {
  width: 100%;
  margin: 0rem 0rem 0rem 0rem;
  
  &__desc {
  }
  
  &__value {
    text-align: right;
    word-break: break-word;
  }
}

@media (--SMALLSCREEN) {
  
  .order-status-table {
  }
}

@media (--MEDIUMSCREEN) {
  
  .order-status-table {
  }
}

@media (--WIDESCREEN) {
  
  .order-status-table {
  }
}

/* order status table end */

/* order status info */

.order-status-info {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 0.5rem;
  
  &__row {
    width: 100%;
    display: grid;
    grid-template-columns: 8rem minmax(0, 1fr);
    align-items: flex-start;
    gap: 1rem;
  }
  
  &__desc {
    font-weight: bold;
    
    &:empty {
      display: none;
    }
  }
  
  &__value {
    
    &--full {
       grid-column: 1/-1;
    }
  }
  
  &__link {
  }
  
  &__qr-image {
    width: 9.5rem;
  }
  
  &__button {
    margin: 0rem 0rem 0rem 0rem;
  }
}

@media (--SMALLSCREEN) {
  
  .order-status-info {
  }
}

@media (--MEDIUMSCREEN) {
  
  .order-status-info {
  }
}

@media (--WIDESCREEN) {
  
  .order-status-info {
  
    &__row {
      grid-template-columns: 12rem minmax(0, 1fr);
    }
  }
}

/* order status info end */

/* order status change payment */

.order-status-change-payment {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  
  &__opener {
  }
  
  &__form {
  }
  
  &__form-list {
    margin: 0rem 0rem 0rem 0rem;
  }
  
  &__entry {
    width: 100%;
    max-width: 100%;
  }
  
  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  &__button {
    width: 100%;
    min-width: 0;
    margin: 0rem 0rem 0rem 0rem;
  }
}

@media (--SMALLSCREEN) {
  
  .order-status-change-payment {
  
    &__entry {
      width: auto;
      margin-right: auto;
    }
    
    &__buttons {
      flex-direction: row;
      flex-wrap: wrap;
    }
    
    &__button {
      width: auto;
    }
  }
}

@media (--MEDIUMSCREEN) {
  
  .order-status-change-payment {
  }
}

@media (--WIDESCREEN) {
  
  .order-status-change-payment {
  }
}

/* order status change payment end */

/* order status basket */

.order-status-basket {
}

@media (--SMALLSCREEN) {
  
  .order-status-basket {
  }
}

@media (--MEDIUMSCREEN) {
  
  .order-status-basket {
  }
}

@media (--WIDESCREEN) {
  
  .order-status-basket {
  }
}

/* order status basket end */

/* order status basket item */

.order-status-basket-item {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.625rem 0.75rem;
  border: 0rem solid var(--border-color-basic);
  border-bottom-width: 1px;
  padding: 1rem 0rem;
  
  &:first-child {
    border-top-width: 1px;
  }
  
  &__block {
    width: calc(50% - 0.5rem);
    flex: none;
    
    &--picture {
      width: 5.5rem;
      flex: none;
      order: 10;
    }
    
    &--name-wrapper {
      flex-grow: 1;
      order: 20;
    }
  
    &--code {
      order: 30;
    }
    
    &--pieces {
      order: 40;
      text-align: right;
    }
    
    &--availability {
      order: 50;
    }
    
    &--price {
      font-weight: bold;
      white-space: nowrap;
      order: 60;
      margin-left: auto;
      text-align: right;
    }
  }
  
  &__name {
    font-weight: bold;
  }
}

@media (--SMALLSCREEN) {
  
  .order-status-basket-item {
  }
}

@media (--MEDIUMSCREEN) {
  
  .order-status-basket-item {
  }
}

@media (--WIDESCREEN) {
  
  .order-status-basket-item {
    display: grid;
    grid-template-columns: 5.5rem 40% 18% 18% auto;
    
    &__block {
      width: auto;
  
      &--pieces {
        text-align: center;
      }
      
      &--code {
        text-align: center;
      }
  
      &--availability {
        text-align: center;
      }
    }
  }
}

/* order status basket item end */

/* order status basket summary */

.order-status-basket-summary {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  font-size: 1.25rem;
  font-weight: bold;
  
  &__desc {
  }
  
  &__value {
  }
  
  &__price {
    white-space: nowrap;
  }
}

@media (--SMALLSCREEN) {
  
  .order-status-basket-summary {
  }
}

@media (--MEDIUMSCREEN) {
  
  .order-status-basket-summary {
  }
}

@media (--WIDESCREEN) {
  
  .order-status-basket-summary {
  }
}

/* order status basket summary end */