@charset "utf-8";

/* EXTERNÍ FONTY */

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto-bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}