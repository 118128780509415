@charset "utf-8";

/* BUTTONY */

.button {
  min-width: 6.25rem;
  min-height: 3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: none;
  font-size: 1rem;
  padding: 0.125rem 1rem;
  margin: 0rem 0rem 1rem 0rem;
  vertical-align: top;
  text-align: center;
  text-decoration: none;
  line-height: 1.5;
  cursor: pointer;
  font-weight: bold;
  transition: 0.3s;
  border: 1px solid #0000;
  border-radius: 0rem;
  background: url(../images/style/icons/loader.svg) left -100rem / 6.25rem auto no-repeat;
  animation: 5s infinite linear paused;
  
  &--primary {
    background-color: #000;
    color: #fff;
  }
  
  &--secondary {
    background-color: #fff;
    color: #000;
    border-color: #000;
  }
  
  &.is-validating {
    animation-name: button-loader;
    animation-play-state: running;
    pointer-events: none;
    background-repeat: repeat;
  }
}

@media (--SMALLSCREEN) {
  
  .button {
  }
}

@media (--MEDIUMSCREEN) {
  
  .button {
  }
}

@media (--WIDESCREEN) {
  
  .button {
  }
}

/* animation button loader */

@keyframes button-loader {
  0% {background-position: 0rem top;}
  50% {background-position: 10rem top;}
  100% {background-position: 20rem top;}
}

/* animation button loader end */