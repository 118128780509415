@charset "utf-8";

/* ESHOPOVÉ KOMPONENTY */

/* pieces picker */

.pieces-picker {
  max-width: 100%;
  height: 2.25rem;
  display: inline-flex;
  vertical-align: top;
  flex: none;
  
  &__entry {
    width: auto;
    height: auto;
    flex: 1 1 auto;
    text-align: center;
    padding: 0rem 0.125rem;
    margin: 0rem 0rem 0rem 0rem;
    border-left: 0rem;
    border-right: 0rem;
    font-size: 0.813rem;
  }
  
  &__handle {
    flex: none;
    width: 2.25rem;
    position: relative;
    background: #e0e0e0;
    cursor: pointer;
    transition: 0.3s;
    
    &:before,
    &:after {
      width: 0.75rem;
      height: 0.125rem;
      display: block;
      content: '';
      background: var(--color-basic);
      position: absolute;
      left: calc(50% - 0.375rem);
      top: calc(50% - 1px);
    }
    
    &--add:after {
      transform:rotate(90deg);
    }
    
    &:hover {
      background: #cfcfcf;
    }
  }
}

@media (--SMALLSCREEN) {
  
  .pieces-picker {
  }
}

@media (--MEDIUMSCREEN) {
  
  .pieces-picker {
  }
}

@media (--WIDESCREEN) {
  
  .pieces-picker {
  }
}

/* pieces picker end */

/* product tags */

.product-tags {
  position: absolute;
  left: 0rem;
  top: 0rem;
  width: 100%;
  height: 100%;
}

@media (--SMALLSCREEN) {
  
  .product-tags {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-tags {
  }
}

@media (--WIDESCREEN) {
  
  .product-tags {
  }
}

/* product tags end */

/* product tag */

.product-tag {
  display: block;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  font-weight: bold;
  color: #fff;
  font-size: 0.75rem;
  
  &--sale {
    background: #b71616;
    left: 0.25rem;
    top: 0.25rem;
  }
  
  &--action {
    background: #2da51c;
    right: 0.25rem;
    top: 0.25rem;
  }
  
  &--new {
    background: #a39302;
    left: 0.25rem;
    bottom: 0.25rem;
  }
  
  &--top {
    background: #4fb3d4;
    right: 0.25rem;
    bottom: 0.25rem;
  }
}

@media (--SMALLSCREEN) {
  
  .product-tag {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-tag {
  }
}

@media (--WIDESCREEN) {
  
  .product-tag {
  }
}

/* product tag end */

/* availability */

.availability {
  
  &--available {
    color: #2da51c;
  }
  
  &--unavailable {
    color: #888;
  }
}

@media (--SMALLSCREEN) {
  
  .availability {
  }
}

@media (--MEDIUMSCREEN) {
  
  .availability {
  }
}

@media (--WIDESCREEN) {
  
  .availability {
  }
}

/* availability end */

/* product subcategories */

.product-subcategories {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 1rem;
}

@media (--SMALLSCREEN) {
  
  .product-subcategories {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-subcategories {
  }
}

@media (--WIDESCREEN) {
  
  .product-subcategories {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

/* product subcategories end */

/* product subcategory */

.product-subcategory {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  padding: 0.5rem;
  border: 0.125rem solid #0003;
  
  &:hover {
    border-color:#0007;
  }
  
  &__picture {
    flex: none;
    width: 2.5rem;
  }
  
  &__headline {
    flex-grow: 1;
    margin: 0rem 0rem 0rem 0rem;
    font-size: 0.875rem;
  }
}

@media (--SMALLSCREEN) {
  
  .product-subcategory {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-subcategory {
  }
}

@media (--WIDESCREEN) {
  
  .product-subcategory {
  }
}

/* product subcategory end */

/* product arrangement */

.product-arrangement {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;
}

@media (--SMALLSCREEN) {
  
  .product-arrangement {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-arrangement {
  }
}

@media (--WIDESCREEN) {
  
  .product-arrangement {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

/* product arrangement */

/* products arrangement openers */

.product-arrangement-openers {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.75rem;
  text-align: right;
}

@media (--SMALLSCREEN) {
  
  .product-arrangement-openers {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-arrangement-openers {
  }
}

@media (--WIDESCREEN) {
  
  .product-arrangement-openers {
    display: none;
  }
}

/* products arrangement openers end */

/* products arrangement opener */

.product-arrangement-opener {
  flex: none;
  padding-bottom: 1.75rem;
  cursor: pointer;
  font-size: 0.875rem;
  background: center bottom / 1.125rem auto no-repeat;
  
  &--filter {
    background-image: url(../images/style/icons/filter.svg);
  }
  
  &--sort {
    background-image: url(../images/style/icons/sort.svg);
  }
}

@media (--SMALLSCREEN) {
  
  .product-arrangement-opener {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-arrangement-opener {
  }
}

@media (--WIDESCREEN) {
  
  .product-arrangement-opener {
  }
}

/* product arrangement opener end */

/* product arrangement list */

.product-arrangement-list {
  display: none;
  
  &--filter {
  }
  
  &--sort {
  }
  
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.125rem 0.75rem;
  }
}

@media (--SMALLSCREEN) {
  
  .product-arrangement-list {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-arrangement-list {
  }
}

@media (--WIDESCREEN) {
  
  .product-arrangement-list {
    display: block !important;
  
    &__content {
      flex-direction: row;
      align-items: flex-start;
    
      ^&--sort & {
        justify-content: flex-end;
      }
    }
  }
}

/* product arrangement list end */

/* product arrangement item */

.product-arrangement-item {
  flex: none;
  text-align: center;
  background: #f2f2f2;
  padding: 0.75rem 0;
  font-size: 0.875rem;
  cursor: pointer;
  
  &.is-active {
    font-weight: bold;
    pointer-events: none;
  }
  
  &--headline {
    display: none;
    cursor: default;
  }
}

@media (--SMALLSCREEN) {
  
  .product-arrangement-item {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-arrangement-item {
  }
}

@media (--WIDESCREEN) {
  
  .product-arrangement-item {
    background: none;
    padding: 0rem;
  
    &:hover:not([class*=headline]) {
      text-decoration: underline;
    }
  
    &--headline {
      display: block;
    }
  }
}

/* product arrangement item end */

/* product filter */

.product-filter {
  
  &__form {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 1rem;
  }
}

@media (--SMALLSCREEN) {
  
  .product-filter {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-filter {
  }
}

@media (--WIDESCREEN) {
  
  .product-filter {
  }
}

/* product filter end */

/* product filter selected */

.product-filter-selected {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 1rem;
  
  &__headline {
    font-weight: bold;
    margin: 0rem 0rem 0rem 0rem;
  }
  
  &__cancel {
    margin: 0rem 0rem 0rem 0rem;
  }
  
  &__cancel-handle {
  }
}

@media (--SMALLSCREEN) {
  
  .product-filter-selected {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-filter-selected {
  }
}

@media (--WIDESCREEN) {
  
  .product-filter-selected {
  }
}

/* product filter selected end */

/* product filter selected list */

.product-filter-selected-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

@media (--SMALLSCREEN) {
  
  .product-filter-selected-list {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-filter-selected-list {
  }
}

@media (--WIDESCREEN) {
  
  .product-filter-selected-list {
  }
}

/* product filter selected list end */

/* product filter selected item */

.product-filter-selected-item {
  min-height: 1.5rem;
  display: inline-flex;
  grid-template-columns: minmax(0, 1fr);
  gap: 0.5rem;
  align-items: center;
  padding: 0.125rem 0.5rem;
  background: #e0e0e0;
  cursor: pointer;
  
  &:after {
    width: 0.688rem;
    height: 0.688rem;
    flex: none;
    content: '';
    transform: rotate(45deg);
    background:
      linear-gradient(#000, #000) 0rem 50% / 100% 1px no-repeat,
      linear-gradient(#000, #000) 50% 0rem / 1px 100% no-repeat;
  }
}

@media (--SMALLSCREEN) {
  
  .product-filter-selected-item {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-filter-selected-item {
  }
}

@media (--WIDESCREEN) {
  
  .product-filter-selected-item {
  }
}

/* product filter selected item end */

/* product filter list */

.product-filter-list {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 1rem;
}

@media (--SMALLSCREEN) {
  
  .product-filter-list {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-filter-list {
  }
}

@media (--WIDESCREEN) {
  
  .product-filter-list {
  }
}

/* product filter list end */

/* product filter item */

.product-filter-item {
  
  &__headline {
    display: block;
    margin: 0rem 0rem 1rem 0rem;
  }
}

@media (--SMALLSCREEN) {
  
  .product-filter-item {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-filter-itemm {
  }
}

@media (--WIDESCREEN) {
  
  .product-filter-item {
  }
}

/* product filter item end */

/* product filter parameters */

.product-filter-parameters {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.5rem 2rem;
}

@media (--SMALLSCREEN) {
  
  .product-filter-parameters {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-filter-parameters {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media (--WIDESCREEN) {
  
  .product-filter-parameters {
  }
}

/* product filter parameters end */

/* product filter parameter */

.product-filter-parameter {
}

@media (--SMALLSCREEN) {
  
  .product-filter-parameter {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-filter-parameter {
  }
}

@media (--WIDESCREEN) {
  
  .product-filter-parameter {
  }
}

/* product filter parameter end */

/* product list */

.product-list {
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: minmax(0, 1fr);
}

@media (--SMALLSCREEN) {
  
  .product-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (--WIDESCREEN) {
  
  .product-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    
    &--three-columns {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}

/* product list end */

/* product list messages */

.product-list-messages {
  grid-column: 1/-1;
}

/* product list messages end */

/* product item */

.product-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  border: 0.125rem solid var(--border-color-basic);
  
  @nest .upsell-popup-products & {
    height: 100%;
  }
  
  &__picture {
    display: block;
    position: relative;
  }
  
  &__headline {
    font-size: 1.25rem;
    margin: 0rem 0rem 0rem 0rem;
    text-align: center;
  }
  
  &__headline-link {
    text-decoration: none;
  }
  
  &__availability-wrapper {
    font-size: 0.75rem;
    text-align: center;
  }
  
  &__block {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: auto 0rem 0rem 0rem;
  }
  
  &__prices {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.75rem;
  }
  
  &__price {
    font-size: 1.25em;
  }
  
  &__old-price {
    font-size: 0.75em;
    text-decoration: line-through;
  }
  
  &__handles {
    width: 100%;
    display: grid;
    grid-template-columns: 6.75rem minmax(0, 1fr);
    align-items: center;
    gap: 0.75rem;
  }
  
  &__button {
    margin: 0rem 0rem 0rem 0rem;
    
    &--buy {
      margin-left: auto;
    }
    
    &--choice {
       grid-column: 1/-1;
    }
  }
  
  &__separately-info {
    text-align: center;
    font-weight: bold;
  }
}

@media (--SMALLSCREEN) {
  
  .product-item {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-item {
  }
}

@media (--WIDESCREEN) {
  
  .product-item {
  }
}

/* product item end */

/* product detail */

.product-detail {
}

@media (--SMALLSCREEN) {
  
  .product-detail {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-detail {
  }
}

@media (--WIDESCREEN) {
  
  .product-detail {
  }
}

/* product detail end */

/* product detail basic */

.product-detail-basic {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 2rem;
  
  &__block {
  }
  
  &__headline {
    
    &--desktop {
      display: none;
    }
  }
  
  &__perex {
  
    &--desktop {
      display: none;
    }
  }
  
  &__separately-info {
    font-weight: bold;
  }
}

@media (--SMALLSCREEN) {
  
  .product-detail-basic {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-detail-basic {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  
    &__headline {
  
      &--mobile {
        display: none;
      }
      
      &--desktop {
        display: block;
      }
    }
  
    &__perex {
  
      &--mobile {
        display: none;
      }
      
      &--desktop {
        display: block;
      }
    }
  }
}

@media (--WIDESCREEN) {
  
  .product-detail-basic {
  }
}

/* product detail basic end */

/* product detail images wrapper */

.product-detail-images-wrapper {
}

@media (--SMALLSCREEN) {
  
  .product-detail-images-wrapper {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-detail-images-wrapper {
  }
}

@media (--WIDESCREEN) {
  
  .product-detail-images-wrapper {
  }
}

/* product detail images wrapper end */

/* product detail images */

.product-detail-images {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.75rem;
  
  &__picture {
    position: relative;
    
    &--basic {
      grid-column: 1/-1;
    }
  }
}

@media (--SMALLSCREEN) {
  
  .product-detail-images {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-detail-images {
  }
}

@media (--WIDESCREEN) {
  
  .product-detail-images {
  }
}

/* product detail images end */

/* product detail row */

.product-detail-row {
  width: 100%;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  align-items: center;
  gap: 0.75rem;
  
  &--variant {
    grid-template-columns: minmax(0, 0.35fr) minmax(0, 0.65fr);
  }
  
  &__desc {
  }
  
  &__value {
    text-align: right;
    
    > * {
      margin-left: auto;
    }
    
    ^&--pieces & {
      width: 6.75rem;
      margin-left: auto;
    }
  }
}

@media (--SMALLSCREEN) {
  
  .product-detail-row {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-detail-row {
  }
}

@media (--WIDESCREEN) {
  
  .product-detail-row {
  }
}

/* product detail row end */

/* product detail info */

.product-detail-info {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 0.25rem;
  margin-bottom: 1.5rem;
  
  &__block {
  }
}

@media (--SMALLSCREEN) {
  
  .product-detail-info {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-detail-info {
  }
}

@media (--WIDESCREEN) {
  
  .product-detail-info {
  }
}

/* product detail info end */

/* product detail handles */

.product-detail-handles {
  margin-bottom: 1.5rem;
  
  &__content {
  }
  
  &__continue {
  }
  
  &__button {
    margin: 0rem 0rem 0rem 0rem;
  }
}

@media (--SMALLSCREEN) {
  
  .product-detail-handles {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-detail-handles {
  }
}

@media (--WIDESCREEN) {
  
  .product-detail-handles {
  }
}

/* product detail handles end */

/* product detail variants */

.product-detail-variants {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 0.5rem;
  margin-bottom: 0.25rem;
}

@media (--SMALLSCREEN) {
  
  .product-detail-variants {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-detail-variants {
  }
}

@media (--WIDESCREEN) {
  
  .product-detail-variants {
  }
}

/* product detail variants end */

/* product detail variant */

.product-detail-variant {
  max-width: 20rem;
  
  &__entry {
    &.is-selection-disabled {
      opacity: 0.4;
    }
  }
}

@media (--SMALLSCREEN) {
  
  .product-detail-variant {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-detail-variant {
  }
}

@media (--WIDESCREEN) {
  
  .product-detail-variant {
  }
}

/* product detail variant end */

/* product detail sharings */

.product-detail-sharings {
}

@media (--SMALLSCREEN) {
  
  .product-detail-sharings {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-detail-sharings {
  }
}

@media (--WIDESCREEN) {
  
  .product-detail-sharings {
  }
}

/* product detail sharings end */

/* product detail description */

.product-detail-desc {
  
  > *:last-child {
    margin-bottom: 0rem;
  }
}

@media (--SMALLSCREEN) {
  
  .product-detail-desc {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-detail-desc {
  }
}

@media (--WIDESCREEN) {
  
  .product-detail-desc {
  }
}

/* product detail description end */

/* product detail parameters */

.product-detail-parameters {
  border-bottom: 1px solid #0003;
}

@media (--SMALLSCREEN) {
  
  .product-detail-parameters {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-detail-parameters {
  }
}

@media (--WIDESCREEN) {
  
  .product-detail-parameters {
  }
}

/* product detail parameters end */

/* product detail parameter */

.product-detail-parameter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  padding: 0.75rem 0rem;
  border-top: 1px solid #0003;
  
  &__desc {
    font-weight: bold;
  }
  
  &__value {
    text-align: right;
  }
}

@media (--SMALLSCREEN) {
  
  .product-detail-parameter {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-detail-parameter {
  }
}

@media (--WIDESCREEN) {
  
  .product-detail-parameter {
  }
}

/* product detail parameter end */

/* product category page products */

.product-category-page-products {
  width: 100%;
  display: grid;
  gap: 0rem 1rem;
  grid-template-columns: minmax(0, 1fr);
  
  &__block {
  }
}

@media (--SMALLSCREEN) {
  
  .product-category-page-products {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-category-page-products {
  }
}

@media (--WIDESCREEN) {
  
  .product-category-page-products {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    
    &__block {
      
      &:nth-of-type(1) {
        margin-top: -0.5rem;
      }
      
      &:nth-of-type(2) {
        grid-column: span 3;
      }
    }
  }
}

/* product category page products end */

/* product related */

.product-related {
  
  &__headline {
  }
}

@media (--SMALLSCREEN) {
  
  .product-related {
  }
}

@media (--MEDIUMSCREEN) {
  
  .product-related {
  }
}

@media (--WIDESCREEN) {
  
  .product-related {
  }
}

/* product related end */

/* upsell popup */

.upsell-popup {
  
  &__headline {
  }
}

@media (--SMALLSCREEN) {
  
  .upsell-popup {
  }
}

@media (--MEDIUMSCREEN) {
  
  .upsell-popup {
  }
}

@media (--WIDESCREEN) {
  
  .upsell-popup {
  }
}

/* upsell popup end */

/* upsell popup buyed */

.upsell-popup-buyed {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.75rem 1rem;
  padding: 0rem 0rem 1rem 0rem;
  margin: 0rem 0rem 1rem 0rem;
  border-bottom: 0.125rem solid var(--border-color-basic);
  
  &__picture {
    flex: none;
    width: 4rem;
  }
  
  &__headline {
    width: calc(100% - 5rem);
    flex-grow: 1;
    margin: 0rem 0rem 0rem 0rem;
    font-size: 1rem;
  }
}

@media (--SMALLSCREEN) {
  
  .upsell-popup-buyed {
  }
}

@media (--MEDIUMSCREEN) {
  
  .upsell-popup-buyed {
  }
}

@media (--WIDESCREEN) {
  
  .upsell-popup-buyed {
  }
}

/* upsell popup buyed end */

/* upsell popup buyed pieces */

.upsell-popup-buyed-pieces {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.75rem;
  flex: none;
  
  &__block {
    
    &--1 {
      flex-grow: 1;
    }
  
    &--2 {
      width: 6.75rem;
      flex: none;
    }
  }
  
  &__headline {
    display: block;
  }
  
  &__edit {
    display: inline-block;
    vertical-align: top;
    font-size: 0.875em;
    text-decoration: underline;
    
    &:hover {
      text-decoration: none;
    }
  }
}

@media (--SMALLSCREEN) {
  
  .upsell-popup-buyed-pieces {
  }
}

@media (--MEDIUMSCREEN) {
  
  .upsell-popup-buyed-pieces {
  }
}

@media (--WIDESCREEN) {
  
  .upsell-popup-buyed-pieces {
  }
}

/* upsell popup buyed pieces end */

/* upsell popup buyed prices */

.upsell-popup-buyed-prices {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  flex: none;
  
  &__desc {
  }
  
  &__value {
    flex: none;
    font-size: 1.25rem;
  }
}

@media (--SMALLSCREEN) {
  
  .upsell-popup-buyed-prices {
  }
}

@media (--MEDIUMSCREEN) {
  
  .upsell-popup-buyed-prices {
  }
}

@media (--WIDESCREEN) {
  
  .upsell-popup-buyed-prices {
  }
}

/* upsell popup buyed prices end */

/* upsell popup products */

.upsell-popup-products {
  margin: 0rem 0rem 1rem 0rem;
  
  &__headline {
  }
  
  &__products {
    overflow: hidden;
  }
  
  &__slick-arrows {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
}

.ui-dialog.ui-dialog-upsell-popup-products {
  max-width: 46.25rem;
}

@media (--SMALLSCREEN) {
  
  .upsell-popup-products {
  }
}

@media (--MEDIUMSCREEN) {
  
  .upsell-popup-products {
  }
}

@media (--WIDESCREEN) {
  
  .upsell-popup-products {
  }
}

/* upsell popup products end */

/* upsell popup buttons */

.upsell-popup-buttons {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 0.5rem 1rem;
  
  &__button {
    margin: 0;
  }
}

@media (--SMALLSCREEN) {
  
  .upsell-popup-buttons {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (--MEDIUMSCREEN) {
  
  .upsell-popup-buttons {
  }
}

@media (--WIDESCREEN) {
  
  .upsell-popup-buttons {
  }
}

/* upsell popup buttons end */