@charset "utf-8";

/* UI DIALOG */

/* ui dialog normalize - normalizuje pluginem generované html */

.ui-widget-overlay {
  background: #ffffffea;
  opacity: 1;
  z-index: 1000;
}

.ui-dialog {
  width: calc(100% - 2 * var(--gap-page)) !important;
  min-width: calc(20rem - 2 * var(--gap-page)) !important;
  max-width: 30rem;
  left: 50% !important;
  transform: translate(-50%, 0);
  background: #fff;
  box-shadow: 0 0 0.625rem 0rem #0003;
  padding: 0;
  z-index: 1001;
  
  & .inner {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  
  & .ui-dialog-titlebar {
    padding: 0;
    
    & .ui-dialog-title {
      display: none;
    }
  }
  
  & .ui-dialog-titlebar-close {
    width: 2rem;
    height: 2rem;
    right: 0.125rem;
    top: 0.125rem;
    margin: 0;
    padding: 0;
    transition: 0.1s;
    z-index: 1;
    transform-origin: center center;
    background: url(../images/style/icons/close.svg) no-repeat center center / 1rem auto transparent;
    
    &:hover {
      transform: scale(1.1);
    }
    
    & span {
      display: none;
    }
  }
  
  & .ui-dialog-content {
    padding: 1.5rem var(--gap-page) var(--gap-page) var(--gap-page);
  }
}

@media (--SMALLSCREEN) {
  
  .ui-dialog {
  }
}

@media (--MEDIUMSCREEN) {
  
  .ui-dialog {
  }
}

@media (--WIDESCREEN) {
  
  .ui-dialog {
  }
}

/* ui dialog normalize end */

/* ui dialog custom - vlastní univerzální komponenty */

.ui-dialog-headline {
}

.ui-dialog-desc {
  font-weight: bold;
  margin: 0rem 0rem 1.5rem 0rem;
}

@media (--SMALLSCREEN) {
}

@media (--MEDIUMSCREEN) {
}

@media (--WIDESCREEN) {
}

/* ui dialog custom end */