@charset "utf-8";

/* ZÁKLADNÍ NASTAVENÍ */

/* resolution settings */

@custom-media --TINYSCREEN (min-width: 30rem); /* 480px */
@custom-media --SMALLSCREEN (min-width: 40rem); /* 640px */
@custom-media --LIGHTSCREEN (min-width: 52.5rem); /* 840px */
@custom-media --MEDIUMSCREEN (min-width: 60rem); /* 960px */
@custom-media --WIDESCREEN (min-width: 80rem); /* 1280px */
@custom-media --LARGESCREEN (min-width: 90rem); /* 1440px */
@custom-media --FULLSCREEN (min-width: 100rem); /* 1600px */

/* resolution settings end */

/* custom selectors */

@custom-selector :--headline
h1, h2, h3, h4, h5, h6, .headline;

@custom-selector :--form-element-basic
[type="text"],
[type="password"],
[type="tel"],
[type="email"],
[type="url"],
[type="number"],
[type="date"],
select, textarea;

@custom-selector :--form-element-choice
[type="checkbox"],
[type="radio"];

/* custom selectors end */

/* width variables */

:root {
  --width-page: 87.5rem; /* nastavuje max. pevnou šířku stránek */
  --width-page-complet: calc(var(--width-page) + (2 * var(--gap-page))); /* nastavuje max. pevnou šířku stránek s paddingy */
  --width-middle: calc(var(--width-page) / 1.5); /* nastavuje středně široký blok */
}

/* width variables end */

/* gap variables */

:root {
  --gap-page: 1.25rem; /* nastavuje spacing pro levý a pravý padding stránek */
  --gap-block: 2rem; /* nastavuje spacing základních bloků */
  --gap-element: 1.5rem; /* nastavuje spacing menších elementů */
  --gap-element-small: 1rem; /* nastavuje spacing menších elementů */
  --gap-text: 1.5rem; /* nastavuje spacing textových elementů */
  
  @media (--SMALLSCREEN) {
  }
  
  @media (--MEDIUMSCREEN) {
    --gap-block: 3rem;
  }
  
  @media (--WIDESCREEN) {
    --gap-block: 4rem;
  }
}

/* gap variables end */

/* font variables */

:root {
  --font-size-basic: 1rem; /* nastavuje výchozí velikost písma pro texty */
  --font-size-small: 0.875rem; /* nastavuje alternativní velikost písma pro texty */
  --font-size-h1: 2rem;
  --font-size-h2: 1.75rem;
  --font-size-h3: 1.5rem;
  --font-size-h4: 1.25rem;
  --font-size-h5: 1.125rem;
  --font-size-h6: 1rem;
  --line-height-basic: 1.5; /* nastavuje výchozí line-height textovým elementům */
  
  @media (--SMALLSCREEN) {
  }
  
  @media (--MEDIUMSCREEN) {
  }
  
  @media (--WIDESCREEN) {
  }
}

/* font variables end */

/* color variables */

:root {
  --color-basic: #000; /* nastavuje základní barvu textu */
  --red: #ff0000;
  --green: #00ff00;
  --blue: #0000ff;
  --border-color-basic: #ddd;
}

/* color variables end */

/* other variables */
/* other variables end */