@charset "utf-8";

/* ZÁKLADNÍ CSS */

*, *:after, *:before {
  box-sizing: border-box;
  outline: none;
}

html {
  height: 100%;
}

body {
  min-width: 20rem;
  height: 100%;
  padding: 0rem;
  margin: 0rem;
  font-size: 100%;
  font-family: roboto, arial, helvetica, sans-serif;
  color: var(--color-basic);
  background: #fff;
  line-height: var(--line-height-basic);
  
  &.is-group-mobile-active {
    overflow: hidden;
  }
}

a, .link {
  color: var(--color-basic);
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.3s;
  
  &:hover {
    text-decoration: none;
  }
}

b, strong {
  font-weight: bold;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  
  @nest a & {
    border: 0rem;
  }
  
  &[src*=default-photo] {
    object-fit: contain;
  }
}

:--headline {
  margin: 0rem 0rem var(--gap-text) 0rem;
  line-height: 1.2;
  display: block;
}

h1 {font-size: var(--font-size-h1);}
h2 {font-size: var(--font-size-h2);}
h3 {font-size: var(--font-size-h3);}
h4 {font-size: var(--font-size-h4);}
h5 {font-size: var(--font-size-h5);}
h6 {font-size: var(--font-size-h6);}

p {
  font-size: var(--font-size-basic);
  margin: 0rem 0rem var(--gap-text) 0rem;
}

table {
  margin: 0rem 0rem var(--gap-text) 0rem;
  border-spacing: 0rem;
  border-collapse: collapse;
  border: 0rem;
}

th, td {
  padding: 0.75rem 0.5rem;
  text-align: left;
  font-size: var(--font-size-basic);
  border: 0rem solid var(--border-color-basic);
  border-top-width: 1px;
  
  &:first-child {
    padding-left: 0;
  }
  
  &:last-child {
    padding-right: 0;
  }
  
  @nest tr:last-of-type & {
    border-bottom-width: 1px;
  }
}

th {
}

td {
}

ul, ol {
  font-size: var(--font-size-basic);
  margin: 0rem 0rem var(--gap-text) 0rem;
  padding: 0rem;
  list-style-position: inside;
  
  @nest li & {
    font-size: 1em;
    margin-top: calc(var(--gap-text) / 2);
    margin-bottom: 0rem;
  }
}

ul {
  list-style: none;
}

ol {
}

li {
  margin: 0rem 0rem calc(var(--gap-text) / 2) 0rem;
  padding: 0rem 0rem 0rem 0rem;
  
  @nest li & {
    padding-left: 1.125rem;
  }
  
  @nest ul & {
    background: linear-gradient(var(--color-basic) , var(--color-basic)) 0rem 0.5em / 0.375rem 0.375rem no-repeat;
    padding-left: 1.125rem;
  }
}

iframe {
  border: 0rem;
  display: block;
  width: 100%;
  margin: 0rem;
  padding: 0rem;
}

.inner {
  width: 100%;
  display: block;
  max-width: var(--width-page-complet);
  padding: 0rem  var(--gap-page);
  margin: 0rem auto;
}

.table-wrapper {
  width: 100%;
  overflow: auto;
}

[disabled],
[disabled] ~ [class*=desc],
:disabled,
:disabled ~ [class*=desc],
.is-disabled,
.is-disabled ~ [class*=desc] {
  opacity: 0.4;
  pointer-events: none;
}

@media (--SMALLSCREEN) {
}

@media (--MEDIUMSCREEN) {
}

@media (--WIDESCREEN) {
}