@charset "utf-8";

/* MENU */

/* menu */

.menu {
  display: none;
}

@media (--SMALLSCREEN) {
  
  .menu {
  }
}

@media (--MEDIUMSCREEN) {
  
  .menu {
  }
}

@media (--WIDESCREEN) {
  
  .menu {
    display: block;
    width: 100%;
    margin-top: 0.5rem;
    
    &__list {
      margin: 0rem;
      padding: 0rem;
      list-style: none;
      display: none;
      font-size: 1rem;
      line-height: 1.5;
      
      ^&__item:hover > & {
        display: block;
      }
    }
    
    &__item {
      margin: 0rem;
      padding: 0rem;
      position: relative;
      background: none;
    }
    
    &__link {
      display: block;
      text-decoration: none;
      padding: 0.5rem 3rem 0.5rem 0.75rem;
      
      &:not([class*=link--1]):hover {
        background: #B0B0B0;
      }
    }
    
    /* 1.level */
    
    &__list--1 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem;
    }
    
    &__item--1 {
      flex: none;
    }
    
    &__link--1 {
      padding: 0rem 0rem;
      font-size: 1rem;
      
      &:hover,
      ^&__item.is-active & {
        color: #888;
      }
    }
    
    /* 2.level */
    
    &__list--2 {
      position: absolute;
      left: -0.75rem;
      width: 13.5rem;
      z-index: 200;
    }
    
    &__link--2 {
      background: #e0e0e0;
      font-size: 1rem;
    }
    
    /* 3.level */
    
    &__link--3 {
      background: #C3C3C3;
      font-size: 1rem;
    }
    
    /* opener */
    
    &__opener {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 2.438rem;
      height: 2.438rem;
      background: #0001;
      cursor: pointer;
      
      &:after {
        border-right: 0.125rem solid var(--color-basic);
        border-bottom: 0.125rem solid var(--color-basic);
        width: 0.5rem;
        height: 0.5rem;
        content: '';
        display: block;
        transform: rotate(45deg);
        position: absolute;
        left: calc(50% - 0.25rem);
        top: calc(50% - 0.313rem);
        transition: 0.3s;
      }
    }
    
    &__item.open > .opener:after {
      transform: rotate(-135deg);
      top: calc(50% - 0.125rem);
    }
    
    &__opener--1 {
      display: none;
    }
  }
}

/* menu end */

/* menu mobile */

.menu-mobile {
  
  &__block {
  
    &--categories {
      border-bottom: 0.25rem solid var(--color-basic);
    }
  }
  
  &__list {
    margin: 0rem;
    padding: 0rem;
    list-style: none;
    display: none;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  &__item {
    margin: 0rem;
    padding: 0rem;
    position: relative;
  }
  
  &__link {
    display: block;
    border-top: 1px solid var(--color-basic);
    text-decoration: none;
    padding: 0.75rem 3.5rem 0.75rem var(--gap-page);
  }
  
  &__picture {
    display: none;
  }

  /* 1.level */
  
  &__list--1 {
    display: block;
  }
  
  &__link--1 {
    background: #e0e0e0;
    font-size: 1rem;
    
    ^&__item--1:first-of-type & {
      border: 0;
    }
  }
  
  /* 2.level */
  
  &__link--2 {
    background: #cfcfcf;
    font-size: 1rem;
  }
  
  /* 3.level */
  
  &__link--3 {
    background: #bfbfbf;
    font-size: 1rem;
  }
  
  /* opener */
  
  &__opener {
    display: block;
    position: absolute;
    right: 0;
    top: 1px;
    width: 3rem;
    height: 3rem;
    background: #0001;
    cursor: pointer;
    
    &:after {
      border-right: 0.125rem solid var(--color-basic);
      border-bottom: 0.125rem solid var(--color-basic);
      width: 0.625rem;
      height: 0.625rem;
      content: '';
      display: block;
      transform: rotate(45deg);
      position: absolute;
      left: calc(50% - 0.25rem);
      top: calc(50% - 0.313rem);
      transition: 0.3s;
  
      
      ^^&__item.is-active > &,
      ^^&__item.is-active > [class*=sub-block] > & {
          transform: rotate(-135deg);
          top: calc(50% - 0.125rem);
      }
    }
  }
}

@media (--SMALLSCREEN) {
  
  .menu-mobile {
  }
}

@media (--MEDIUMSCREEN) {
  
  .menu-mobile {
  }
}

@media (--WIDESCREEN) {
  
  .menu-mobile {
  }
}

/* menu mobile end */

/* menu secondary */

.menu-secondary {
  
  &__list {
    margin: 0rem;
    padding: 0rem;
    list-style: none;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  &__item {
    margin: 0rem;
    padding: 0rem;
    background: none;
  }
  
  &__link {
    display: inline-block;
    vertical-align: top;
    font-size: 1rem;
  }
  
  /* 1.level */
  
  &__list--1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  &__link--1 {
  }
}

@media (--SMALLSCREEN) {
  
  .menu-secondary {
  }
}

@media (--MEDIUMSCREEN) {
  
  .menu-secondary {
    
    &__link {
    }
  
    &__list--1 {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      gap: 1.25rem;
    }
  }
}

@media (--WIDESCREEN) {
  
  .menu-secondary {
  }
}

/* menu secondary end */

/* menu categories */

.menu-categories {
  display: none;
}

@media (--SMALLSCREEN) {
  
  .menu-categories {
  }
}

@media (--MEDIUMSCREEN) {
  
  .menu-categories {
  }
}

@media (--WIDESCREEN) {
  
  .menu-categories {
    display: block;
    background: #e0e0e0;
    position: relative;
    z-index: 175;
    margin-bottom: 1.25rem;
    
    &__content {
      position: relative;
    }
    
    &__list {
      margin: 0rem;
      padding: 0rem;
      list-style: none;
      font-size: 1rem;
      line-height: 1.5;
    }
  
    &__item {
      margin: 0rem;
      padding: 0rem;
      background: none;
    }
  
    &__link {
      display: block;
      text-decoration: none;
      
      &:hover {
        color: #888;
      }
    }
    
    &__picture {
      flex: none;
      width: 4.5rem;
      margin: 0rem 0rem 0rem 0rem;
    }
  
    &__sub-block {
      flex-grow: 1;
      padding: 0rem 0rem 0rem 0rem;
    }
  
    /* 1.level */
  
    &__list--1 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem;
    }
  
    &__item--1 {
    }
  
    &__link--1 {
      padding: 0.75rem 0rem 0.75rem 0rem;
      font-weight: bold;
      font-size: 1rem;
      
      ^&__item.is-active & {
        color: #888;
      }
    }
  
    /* 2.level */
    
    &__list--2 {
      width: 100%;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      gap: 0.5rem;
      display: none;
      background: #fff;
      left: 0rem;
      top: auto;
      max-width: calc(var(--width-page-complet) - (2 * var(--gap-page)));
      position: absolute;
      box-shadow: 0 0.125rem 0.25rem 0rem #0003;
  
      ^&__item:hover & {
        display: grid;
      }
    }
  
    &__item--2 {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 0.75rem;
      padding: 1.25rem;
    }
  
    &__link--2 {
      display: inline-block;
      vertical-align: top;
      font-weight: bold;
      font-size: 1rem;
    }
  
    /* 3.level */
  
    &__list--3 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 0.75rem;
    }
    
    &__item--3 {
    }
    
    &__link--3 {
      font-size: 0.813rem;
    }
  
    /* opener */
    
    &__opener {
      display: none;
    }
  }
}

/* menu categories end */

/* menu categories vertical */

.menu-categories-vertical {
  display: none;
}

@media (--SMALLSCREEN) {
  
  .menu-categories-vertical {
  }
}

@media (--MEDIUMSCREEN) {
  
  .menu-categories-vertical {
  }
}

@media (--WIDESCREEN) {
  
  .menu-categories-vertical {
    display: block;
    
    &__list {
      margin: 0rem 0rem 0rem 0rem;
      padding: 0rem 0rem 0rem 1.25rem;
      list-style: none;
      font-size: 1rem;
      line-height: 1.5;
    }
  
    &__item {
      margin: 0rem;
      padding: 0rem;
      background: none;
    }
  
    &__link {
      display: inline-block;
      vertical-align: top;
      text-decoration: none;
      margin: 0.25rem 0rem;
      font-size: 0.875rem;
      
      &:hover,
      ^&__item.is-active > & {
        text-decoration: underline;
      }
    }
  
    /* 1.level */
  
    &__list--1 {
      padding-left: 0rem;
    }
  
    &__item--1 {
      border-top: 1px solid #0003;
    
      &:first-of-type {
        border: 0rem;
      }
    }
    
    &__link--1 {
      margin: 0.5rem 0rem 0.5rem 0rem;
      font-weight: bold;
      font-size: 1rem;
    }
  
    /* 2.level */
    
    &__list--2 {
      padding-bottom: 0.75rem;
    }
  }
}

/* menu categories vertical end */