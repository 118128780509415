@charset "utf-8";

/* GENERICKÉ KOMPONENTY */

/* page header basic */

.page-header-basic {
  background: #f2f2f2;
  padding: 0.75rem 0rem;
  
  &__content {
    display: flex;
    gap: 0.75rem;
    flex-wrap: wrap;
    align-items: center;
  }
}

@media (--SMALLSCREEN) {
  
  .page-header-basic {
  }
}

@media (--MEDIUMSCREEN) {
  
  .page-header-basic {
  }
}

@media (--WIDESCREEN) {
  
  .page-header-basic {
  }
}

/* page header basic end */

/* page logo */

.page-logo {
  width: 9rem;
  flex: none;
  display: block;
  
  &__image {
    width: 100%;
  }
}

@media (--SMALLSCREEN) {
  
  .page-logo {
  }
}

@media (--MEDIUMSCREEN) {
  
  .page-logo {
  }
}

@media (--WIDESCREEN) {
  
  .page-logo {
  }
}

/* page logo end */

/* group mobile opener */

.group-mobile-opener {
  width: 2.25rem;
  height: 2.25rem;
  flex: none;
  margin: 0rem 0rem 0rem auto;
  cursor: pointer;
  background: url(../images/style/icons/menu.svg) no-repeat center center / 1.875rem auto;
  
  &.is-active {
    background-image: url(../images/style/icons/close.svg);
    background-size: 1.375rem auto;
  }
}

@media (--SMALLSCREEN) {
  
  .group-mobile-opener {
  }
}

@media (--MEDIUMSCREEN) {
  
  .group-mobile-opener {
  }
}

@media (--WIDESCREEN) {
  
  .group-mobile-opener {
    display: none;
  }
}

/* group mobile opener */

/* group mobile */

.group-mobile {
  width: 100%;
  position: fixed;
  left: 0rem;
  top: 0rem;
  overflow: hidden;
  
  &:not(.is-active) {
    height: 0rem !important;
    transition: 0s 0.5s;
  }
  
  &.is-active {
    overflow: auto;
  }
  
  &__cover {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0rem;
    top: 0rem;
    background: #000d;
    opacity: 0;
    transition: 0.5s;
  
    ^&.is-active & {
      opacity: 1;
    }
  }
  
  &__content {
    display: none;
  }
}

@media (--SMALLSCREEN) {
  
  .group-mobile {
  }
}

@media (--MEDIUMSCREEN) {
  
  .group-mobile {
  }
}

@media (--WIDESCREEN) {
  
  .group-mobile {
    display: none;
  }
}

/* group mobile end */

/* info bar */

.info-bar {
  width: 100%;
  position: sticky;
  z-index: 250;
  left: 0rem;
  top: 0rem;
  background: #e0e0e0;
  padding: 0.75rem 0rem;
  
  &__desc {
    margin: 0rem 1.75rem 0rem 0rem;
    font-size: 0.75rem;
  }
  
  &__close {
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    right: 0.875rem;
    top: 50%;
    transform: translateY(-50%);
    background: url(../images/style/icons/close.svg) no-repeat center center / 0.75rem auto;
    cursor: pointer;
  }
}

@media (--SMALLSCREEN) {
  
  .info-bar {
  }
}

@media (--MEDIUMSCREEN) {
  
  .info-bar {
  }
}

@media (--WIDESCREEN) {
  
  .info-bar {
  }
}

/* info bar end */

/* picture */

.picture {
  width: 100%;
  display: block;
  position: relative;
  padding-top: 75%;
  
  &--photo-item {
    padding-top: 75%;
  }
  
  &--product {
    padding-top: 75%;
  }
  
  &--product-category {
    padding-top: 100%;
  }
  
  &--news {
    padding-top: 75%;
  }
  
  &--event {
    padding-top: 75%;
  }
  
  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0rem;
    top: 0rem;
    object-fit: cover;
    
    ^&--fit-contain & {
      object-fit: contain;
    }
  }
}

@media (--SMALLSCREEN) {
  
  .picture {
  }
}

@media (--MEDIUMSCREEN) {
  
  .picture {
  }
}

@media (--WIDESCREEN) {
  
  .picture {
  }
}

/* picture end */

/* message */

.message {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: none;
  min-height: 3rem;
  margin-bottom: 1rem;
  padding: 0.125rem 0.75rem 0.125rem 2.75em;
  text-align: left;
  font-size: var(--font-size-basic);
  border: 0.125rem solid transparent;
  background: 0.75em center / 1.625em auto no-repeat #fff;
  opacity: 0;
  animation: message 0.5s 1.5s forwards;
  
  &--success {
    background-image: url(../images/style/icons/success.svg) ;
    border-color: #2da51c;
    color: #2da51c;
  }
  
  &--error {
    background-image: url(../images/style/icons/error.svg);
    border-color: #b71616;
    background-size: 1.313em auto;
    color: #b71616;
  }
  
  &--important {
    background-image: url(../images/style/icons/important.svg);
    border-color: #b71616;
    background-size: 0.313em auto;
    color: #b71616;
    background-position: 1.375em center;
  }
  
  &--notice {
    background-image: url(../images/style/icons/notice.svg);
    border-color: #a39302;
    background-size: 0.313em auto;
    color: #a39302;
    background-position: 1.375em center;
  }
  
  &--question {
    background-image: url(../images/style/icons/question.svg);
    border-color: #a39302;
    background-size: 1em auto;
    color: #a39302;
    background-position: 0.938em center;
  }
  
  & > * {
    color: inherit;
    font-size: 1em;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  
  & a {
    opacity: 0.7;
    color: inherit;
  }
  
  &:nth-child(1) {animation-delay: 0.5s;}
  &:nth-child(2) {animation-delay: 0.75s;}
  &:nth-child(3) {animation-delay: 1s;}
  &:nth-child(4) {animation-delay: 1.25s;}
}

@keyframes message {
  0% {opacity:0;}
  100% {opacity:1;}
}

@media (--SMALLSCREEN) {
  
  .message {
  }
}

@media (--MEDIUMSCREEN) {
  
  .message {
  }
}

@media (--WIDESCREEN) {
  
  .message {
  }
}

/* message end */

/* flash messages */

.flash-messages {
  width: 100%;
  position: fixed;
  left: 0;
  top: 7.75rem;
  background: #ffffffe5;
  box-shadow: 0 0 0.25rem #0005;
  z-index: 1000;
  
  &__content {
  
    > * {
      
      &:first-child {
        margin-top: 1rem;
      }
  
      &:last-child {
        margin-bottom: 1rem;
      }
    }
  }
}

@media (--SMALLSCREEN) {
  
  .flash-messages {
  }
}

@media (--MEDIUMSCREEN) {
  
  .flash-messages {
    top: 11.5rem;
  }
}

@media (--WIDESCREEN) {
  
  .flash-messages {
  }
}

/* flash messages end */

/* fullsize loader */

.fullsize-loader {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1010;
  left: 0rem;
  top: 0rem;
  background: rgba(248, 248, 248, 0.9);
  display: none;
  
  &.is-active {
    display: flex;
  }
  
  &__animation {
    width: 5.25rem;
    height: 5.25rem;
    display: block;
    background: url(../images/style/icons/loader-2.svg) center center / 100% auto no-repeat;
    animation: fullsize-loader 2s infinite linear paused;
    
    ^&.is-active & {
      animation-name: fullsize-loader;
      animation-play-state: running;
    }
  }
}

@media (--SMALLSCREEN) {
  
  .fullsize-loader {
  }
}

@media (--MEDIUMSCREEN) {
  
  .fullsize-loader {
    
    &__animation {
      width: 7.25rem;
      height: 7.25rem;
    }
  }
}

@media (--WIDESCREEN) {
  
  .fullsize-loader {
  }
}

/* fullsize loader end */

/* animation fullsize loader */

@keyframes fullsize-loader {
  from {transform:rotate(0)}
  to {transform: rotate(360deg)}
}

/* animation fullsize loader end */

/* breadcrumbs */

.breadcrumbs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  font-size: 0.75rem;
  
  &__item {
  }
  
  &__separator {
    
    &:after {
      content: '>';
    }
  }
}

@media (--SMALLSCREEN) {
  
  .breadcrumbs {
  }
}

@media (--MEDIUMSCREEN) {
  
  .breadcrumbs {
  }
}

@media (--WIDESCREEN) {
  
  .breadcrumbs {
  }
}

/* breadcrumbs end */

/* pagination wrapper */

.pagination-wrapper {
  
  &:empty {
    display: none;
  }
}

@media (--SMALLSCREEN) {
  
  .pagination-wrapper {
  }
}

@media (--MEDIUMSCREEN) {
  
  .pagination-wrapper {
  }
}

@media (--WIDESCREEN) {
  
  .pagination-wrapper {
  }
}

/* pagination wrapper end */

/* pagination */

.pagination {
  text-align: center;
  
  &__button {
    margin-left: auto;
    margin-right: auto;
  }
  
  &__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  
  &__item {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    flex: none;
    background: #888;
    color: #fff;
    font-weight: bold;
    transition: 0.3s;
    cursor: pointer;
    position: relative;
    
    &--shift {
      
      &:after {
        border-left:0.125rem solid #fff;
        border-bottom:0.125rem solid #fff;
        width:0.625rem;
        height:0.625rem;
        content:'';
        display:block;
        transform:rotate(0deg);
        position:absolute;
        left:0.813rem;
        transform:rotate(45deg);
        top:calc(50% - 0.313rem);
      }
    }
    
    &--next {
      transform:rotateY(180deg);
    }
    
    &:hover,
    &.is-active {
      background: var(--color-basic);
    }
  }
}

@media (--SMALLSCREEN) {
  
  .pagination {
  }
}

@media (--MEDIUMSCREEN) {
  
  .pagination {
  }
}

@media (--WIDESCREEN) {
  
  .pagination {
  }
}

/* pagination end */

/* slick list, slick track, slick slide */

.slick-list {
  
  @nest .upsell-popup-products & {
    margin: 0rem -0.5rem;
  }
}

.slick-track {
  
  @nest .upsell-popup-products & {
    display: flex;
  }
}

.slick-slide {
  
  @nest .upsell-popup-products & {
    margin: 0 0.5rem;
    height: auto;
  
    & > * {
      height: 100%;
    }
  }
}

/* slick list, slick track, slick slide end */

/* slick arrow */

.slick-arrow {
  width: 2.5em;
  height: 2.5em;
  display: block;
  transition: 0.3s;
  color: transparent;
  overflow: hidden;
  font-size: 1rem;
  position: absolute;
  top: 0em;
  z-index: 1;
  background: #d8d8d8;
  cursor: pointer;
  
  &:hover {
    background: #c4c4c4;
  }
  
  &:after {
    width: 0.625em;
    height: 0.625em;
    display: block;
    content: '';
    border-left: 0.125rem solid var(--color-basic);
    border-bottom: 0.125rem solid var(--color-basic);
    position: absolute;
    left: calc(50% - 0.188em);
    top: calc(50% - 0.313em);
    transform: rotate(45deg);
  }
  
  @nest .upsell-popup-products & {
    position: relative;
  }
}

.slick-next {
  transform: rotate(180deg);
}

.slick-disabled {
  pointer-events: none;
  opacity: 0.4;
}

@media (--SMALLSCREEN) {
  
  .slick-arrow {
  }
}

@media (--MEDIUMSCREEN) {
  
  .slick-arrow {
  }
}

@media (--WIDESCREEN) {
  
  .slick-arrow {
  }
}

/* slick arrow end */

/* slick dots */

.slick-dots {
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5em;
  font-size: 1rem;
  line-height: 1;
  margin: 0rem;
  padding: 0rem;
  list-style: none;
  vertical-align: top;
  
  li {
    width: 1em;
    height: 1em;
    flex: none;
    margin: 0rem;
    padding: 0rem;
    transition: 0.3s;
    background: #d8d8d8;
    border: 1px solid #fff;
    
    &:hover,
    &.slick-active {
      background: #000;
    }
  }
  
  button {
    display: none;
  }
}

@media (--SMALLSCREEN) {
  
  .slick-dots {
  }
}

@media (--MEDIUMSCREEN) {
  
  .slick-dots {
  }
}

@media (--WIDESCREEN) {
  
  .slick-dots {
  }
}

/* slick dots end */

/* go back */

.go-back {
  
  &__link {
    
    &:before {
      display: inline-block;
      content: '<';
      text-decoration: none;
      vertical-align: top;
      padding-right: 0.25rem;
    }
  }
}

@media (--SMALLSCREEN) {
  
  .go-back {
  }
}

@media (--MEDIUMSCREEN) {
  
  .go-back {
  }
}

@media (--WIDESCREEN) {
  
  .go-back {
  }
}

/* go back end */

/* intro */

.intro {
  
  &__headline {
  }
  
  &__desc {
    font-weight: bold;
  }
}

@media (--SMALLSCREEN) {
  
  .intro {
  }
}

@media (--MEDIUMSCREEN) {
  
  .intro {
  }
}

@media (--WIDESCREEN) {
  
  .intro {
  }
}

/* intro end */

/* text block */

.text-block {
}

@media (--SMALLSCREEN) {
  
  .text-block {
  }
}

@media (--MEDIUMSCREEN) {
  
  .text-block {
  }
}

@media (--WIDESCREEN) {
  
  .text-block {
  }
}

/* text block end */

/* photo list */

.photo-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1.25rem;
}

@media (--SMALLSCREEN) {
  
  .photo-list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (--MEDIUMSCREEN) {
  
  .photo-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (--WIDESCREEN) {
  
  .photo-list {
  }
}

/* photo list end */

/* photo item */

.photo-item {
  
  &:hover {
  }
  
  &__picture-wrapper {
  }
}

@media (--SMALLSCREEN) {
  
  .photo-item {
  }
}

@media (--MEDIUMSCREEN) {
  
  .photo-item {
  }
}

@media (--WIDESCREEN) {
  
  .photo-item {
  }
}

/* photo item end */

/* file list */

.file-list {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 1.25rem;
}

@media (--SMALLSCREEN) {
  
  .file-list {
  }
}

@media (--MEDIUMSCREEN) {
  
  .file-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (--WIDESCREEN) {
  
  .file-list {
  }
}

/* file list end */

/* file item */

.file-item {
  border: 1px solid #0003;
  padding: 0.75rem 0.75rem 0.75rem 2.75rem;
  text-decoration: none;
  background: url(../images/style/icons/filegallery.svg) no-repeat 0.75rem center / 1.25rem auto;
  
  &:hover {
    text-decoration: underline;
  }
  
  &__headline {
    font-weight: normal;
  }
  
  &__desc {
    font-size: 0.75rem;
    padding: 0.25rem 0rem 0rem 0rem;
  }
  
  &__size {
  }
  
  &__extension {
    display: none;
  }
}

@media (--SMALLSCREEN) {
  
  .file-item {
  }
}

@media (--MEDIUMSCREEN) {
  
  .file-item {
  }
}

@media (--WIDESCREEN) {
  
  .file-item {
  }
}

/* file item end */

/* video list */

.video-list {
  width: 100%;
  display: grid;
  grid-template-columns: 0, minmax(0, 1fr);
  gap: 1.25rem;
}

@media (--SMALLSCREEN) {
  
  .video-list {
  }
}

@media (--MEDIUMSCREEN) {
  
  .video-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (--WIDESCREEN) {
  
  .video-list {
  }
}

/* video list end */

/* video item */

.video-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  
  &__headline {
    margin: 0rem 0rem 0rem 0rem;
  }
  
  &__desc {
    margin: 0rem 0rem 0rem 0rem;
  }
  
  &__video {
    position: relative;
    padding-top: 56.25%;
    margin-top: auto;
  }
  
  &__iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0rem;
    top: 0rem;
  }
}

@media (--SMALLSCREEN) {
  
  .video-item {
  }
}

@media (--MEDIUMSCREEN) {
  
  .video-item {
  }
}

@media (--WIDESCREEN) {
  
  .video-item {
  }
}

/* video item end */

/* contact form */

.contact-form {
  
  &__button {
    margin: 0rem auto 0rem 0rem;
  }
}

@media (--SMALLSCREEN) {
  
  .contact-form {
  }
}

@media (--MEDIUMSCREEN) {
  
  .contact-form {
  }
}

@media (--WIDESCREEN) {
  
  .contact-form {
  }
}

/* contact form end */

/* socials */

.socials {
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  vertical-align: top;
}

@media (--SMALLSCREEN) {
  
  .socials {
  }
}

@media (--MEDIUMSCREEN) {
  
  .socials {
  }
}

@media (--WIDESCREEN) {
  
  .socials {
  }
}

/* socials end */

/* social */

.social {
  width: 2.5rem;
  height: 2.5rem;
  flex: none;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  
  &:hover{
    filter: brightness(125%);
  }
  
  &--email {
    background: url(../images/style/icons/email.svg) center center / 1.25rem auto no-repeat #649E2B;
  }
  
  &--facebook {
    background: url(../images/style/icons/facebook.svg) center center / 0.625rem auto no-repeat #3a559f;
  }
  
  &--twitter {
    background: url(../images/style/icons/twitter.svg) center center / 1.5rem auto no-repeat #3b88c3;
  }
  
  &--instagram {
    background: url(../images/style/icons/instagram.svg) center center / 1.375rem auto no-repeat #cd3b89;
  }
  
  &--youtube {
    background: url(../images/style/icons/youtube.svg) calc(50% + 0.125rem) center / 0.938rem auto no-repeat #cc0000;
  }
  
  &--linkedin {
    background: url(../images/style/icons/linkedin.svg) center calc(50% - 0.125rem) / 1.25rem auto no-repeat #0073b0;
  }
  
  &__desc {
    display: none;
  }
}

@media (--SMALLSCREEN) {
  
  .social {
  }
}

@media (--MEDIUMSCREEN) {
  
  .social {
  }
}

@media (--WIDESCREEN) {
  
  .social {
  }
}

/* social end */

/* sharings */

.sharings {
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  vertical-align: top;
}

@media (--SMALLSCREEN) {
  
  .sharings {
  }
}

@media (--MEDIUMSCREEN) {
  
  .sharings {
  }
}

@media (--WIDESCREEN) {
  
  .sharings {
  }
}

/* sharings end */

/* sharing */

.sharing {
  width: 2.5rem;
  height: 2.5rem;
  flex: none;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  
  &:hover{
    filter: brightness(125%);
  }
  
  &--email {
    background: url(../images/style/icons/email.svg) center center / 1.25rem auto no-repeat #649E2B;
  }
  
  &--facebook {
    background: url(../images/style/icons/facebook.svg) center center / 0.625rem auto no-repeat #3a559f;
  }
  
  &--twitter {
    background: url(../images/style/icons/twitter.svg) center center / 1.5rem auto no-repeat #3b88c3;
  }
  
  &__desc {
    display: none;
  }
}

@media (--SMALLSCREEN) {
  
  .sharing {
  }
}

@media (--MEDIUMSCREEN) {
  
  .sharing {
  }
}

@media (--WIDESCREEN) {
  
  .sharing {
  }
}

/* sharing end */

/* newsletter registration */

.newsletter-registration {
  margin: 0rem auto 0rem auto;
  max-width: 30rem;
  
  &__headline {
  }
  
  &__form {
  }
  
  &__button {
    margin: 0rem auto 0rem 0rem;
  }
}

@media (--SMALLSCREEN) {
  
  .newsletter-registration {
  }
}

@media (--MEDIUMSCREEN) {
  
  .newsletter-registration {
  }
}

@media (--WIDESCREEN) {
  
  .newsletter-registration {
  }
}

/* newsletter registration end */

/* searchbox */

.searchbox {
  width: 100%;
  position: absolute;
  left: 0;
  top: 100%;
  background: #e0e0e0;
  z-index: 175;
  padding: 0.75rem 0rem;
  display: none;
  
  &__content {
    max-width: 28.125rem;
    margin: 0 auto;
    padding: 0 var(--gap-page);
  }
  
  &__form {
    position: relative;
  }
}

@media (--SMALLSCREEN) {
  
  .searchbox {
  }
}

@media (--MEDIUMSCREEN) {
  
  .searchbox {
  }
}

@media (--WIDESCREEN) {
  
  .searchbox {
  }
}

/* searchbox end */

/* searchbox handle */

.searchbox-handle {
  width: 100%;
  display: flex;
  gap: 0rem;
  
  &__entry {
    width: auto;
    height: 2.75rem;
    flex: 1 1 auto;
    padding-right: 2rem;
    font-size: 0.75rem;
    margin: 0rem auto;
    background-image: url(../images/style/icons/loader-3.gif);
    background-position: -10rem center;
    background-size: 1.313rem auto;
    
    &.is-searching {
      background-position: calc(100% - 0.25rem) center;
    }
  }
  
  &__button {
    width: 2.75rem;
    flex: none;
    background: url(../images/style/icons/search.svg) center center / 1.25rem auto no-repeat #cfcfcf;
  }
  
  &__button-text {
    display: none;
  }
}

@media (--SMALLSCREEN) {
  
  .searchbox-handle {
  }
}

@media (--MEDIUMSCREEN) {
  
  .searchbox-handle {
  }
}

@media (--WIDESCREEN) {
  
  .searchbox-handle {
  }
}

/* searchbox handle end */

/* searchbox suggestion list */

.searchbox-suggestion-list {
  width: calc(100% - 2.875rem);
  max-height: 18.75rem;
  display: flex;
  flex-direction: column;
  gap: 0rem;
  position: absolute;
  left: 1px;
  background: #fff;
  overflow: auto;
  box-shadow: 0 0 0.188rem rgba(0, 0, 0, 0.4);
}

@media (--SMALLSCREEN) {
  
  .searchbox-suggestion-list {
  }
}

@media (--MEDIUMSCREEN) {
  
  .searchbox-suggestion-list {
  }
}

@media (--WIDESCREEN) {
  
  .searchbox-suggestion-list {
  }
}

/* searchbox suggestion list end */

/* searchbox suggestion item */

.searchbox-suggestion-item {
  width: 100%;
  display: grid;
  grid-template-columns: 1.875rem minmax(0, 1fr);
  gap: 0.75rem;
  padding: 0.5rem;
  text-decoration: none;
  line-height: var(--line-height-basic);
  
  &--result-zero {
    grid-template-columns: minmax(0, 1fr);
    font-weight: bold;
    font-size: 0.75rem;
  }
  
  &:hover {
    background: #f2f2f2;
  }
  
  &__image {
    flex: none;
    width: 100%;
  }
  
  &__info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0rem 0.75rem;
    font-size: 0.875rem;
    color: #888;
    line-height: 1.25;
  }
  
  &__headline {
    width: 100%;
    flex: none;
    color: #000;
  }
  
  &__price-value {
  }
}

@media (--SMALLSCREEN) {
  
  .searchbox-suggestion-item {
  }
}

@media (--MEDIUMSCREEN) {
  
  .searchbox-suggestion-item {
  }
}

@media (--WIDESCREEN) {
  
  .searchbox-suggestion-item {
  }
}

/* searchbox suggestion item end */

/* error page info */

.error-page-info {
  max-width: 40rem;
  text-align: center;
  margin: 0rem auto 0rem auto;
  padding: 2rem 0rem;
  
  > *:last-child {
    margin-bottom: 0rem;
  }
  
  &__headline {
    line-height: 1.2;
    font-weight: bold;
    font-size: 4rem;
  }
  
  &__desc {
  }
  
  &__button {
    width: auto;
    margin: 0rem auto 0rem auto;
  }
}

@media (--SMALLSCREEN) {
  
  .error-page-info {
  }
}

@media (--MEDIUMSCREEN) {
  
  .error-page-info {
    padding: 4rem 0rem;
    
    &__headline {
      font-size: 4.5rem;
    }
  }
}

@media (--WIDESCREEN) {
  
  .error-page-info {
    padding: 6rem 0rem;
    
    &__headline {
      font-size: 6rem;
    }
  }
}

/* error page info end */

/* page footer basic */

.page-footer-basic {
  background: #f2f2f2;
  padding: var(--gap-block) 0rem;
  text-align: center;
  
  &__content {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 1.25rem;
  }
}

@media (--SMALLSCREEN) {
  
  .page-footer-basic {
  }
}

@media (--MEDIUMSCREEN) {
  
  .page-footer-basic {
    text-align: left;
  }
}

@media (--WIDESCREEN) {
  
  .page-footer-basic {
  }
}

/* page footer basic end */

/* page footer block */

.page-footer-block {
}

@media (--SMALLSCREEN) {
}

@media (--MEDIUMSCREEN) {
  
  .page-footer-block {
  }
}

@media (--WIDESCREEN) {
  
  .page-footer-block {
  }
}

/* page footer block end */

/* page footer info */

.page-footer-info {
  
  p {
  }
  
  > *:last-child {
    margin-bottom: 0;
  }
}

@media (--SMALLSCREEN) {
  
  .page-footer-info {
  }
}

@media (--MEDIUMSCREEN) {
  
  .page-footer-info {
  }
}

@media (--WIDESCREEN) {
  
  .page-footer-info {
  }
}

/* page footer info end */

/* page footer socials */

.page-footer-socials {
  text-align: center;
}

@media (--SMALLSCREEN) {
  
  .page-footer-socials {
  }
}

@media (--MEDIUMSCREEN) {
  
  .page-footer-socials {
  }
}

@media (--WIDESCREEN) {
  
  .page-footer-socials {
  }
}

/* page footer socials end */

/* copyright */

.copyright {
  text-align: center;
  background: #d8d8d8;
  padding: 1.25rem 0rem;
  
  &__desc {
    margin: 0rem 0rem 0rem 0rem;
    font-size: 0.75rem;
  }
  
  &__link {
  }
}

@media (--SMALLSCREEN) {
  
  .copyright {
  }
}

@media (--MEDIUMSCREEN) {
  
  .copyright {
  }
}

@media (--WIDESCREEN) {
  
  .copyright {
  }
}

/* copyright end */

/* cookies confirm */

.cookies-confirm {
  width: 100%;
  max-height: calc(100% - 1rem);
  position: fixed;
  left: 0;
  bottom: 0;
  display: none;
  align-items: center;
  flex-direction: column;
  z-index: 275;
  background: white;
  box-shadow: 0 0 1.25rem #0004;
  overflow: auto;
  
  &--popup {
    height: 100%;
    max-height: none;
    top: 0;
    bottom: auto;
    justify-content: center;
    background: none;
    overflow: hidden;
  }
  
  &.is-active {
    display: flex;
  }
  
  &__cover {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    background: #fffe;
    flex: none;
  
    ^&--popup & {
      display: block;
    }
  }
  
  &__content {
    width: 100%;
    max-width: 90rem;
    padding: 1rem 1.5rem 0rem 1.5rem;
    position: relative;
    flex: none;
  
    ^&--popup & {
      width: calc(100% - 2rem);
      max-width: 40rem;
      max-height: calc(100% - 2rem);
      background: white;
      box-shadow: 0 0 1.25rem #0004;
      overflow: auto;
    }
  }
  
  &__close {
    width: 1.5rem;
    height: 1.5rem;
    position: sticky;
    top: 0;
    right: 0rem;
    flex: none;
    margin: 0rem 0rem -1.5rem auto;
    display: block;
    cursor: pointer;
    z-index: 1;
  
    &:before,
    &:after {
      width: 0.875rem;
      height: 0.125rem;
      background: #000;
      display: block;
      content: '';
      position: absolute;
      left: 0.25rem;
      top: 0.75rem;
    }
  
    &:before {
      transform: rotate(-45deg);
    }
  
    &:after {
      transform: rotate(45deg);
    }
  
    ^&--popup & {
      transform: translate(1.5rem, -1rem);
    }
  }
}

@media (--SMALLSCREEN) {
  
  .cookies-confirm {
  }
}

@media (--MEDIUMSCREEN) {
  
  .cookies-confirm {
  }
}

@media (--WIDESCREEN) {
  
  .cookies-confirm {
  }
}

/* cookies confirm end */

/* cookies confirm info */

.cookies-confirm-info {
  font-size: 0.875rem;
  
  &__headline {
    display: block;
  }
  
  &__desc {
    
    p,
    ul, ol,
    th, td {
      font-size: 1em;
    }
  
    > *:last-child {
      margin-bottom: 0rem;
    }
  }
}

@media (--SMALLSCREEN) {
  
  .cookies-confirm-info {
  }
}

@media (--MEDIUMSCREEN) {
  
  .cookies-confirm-info {
  }
}

@media (--WIDESCREEN) {
  
  .cookies-confirm-info {
  }
}

/* cookies confirm info end */

/* cookies confirm settings */

.cookies-confirm-settings {
  max-width: 40rem;
  margin: 0 auto;
  
  &__list {
  }
}

@media (--SMALLSCREEN) {
  
  .cookies-confirm-settings {
  }
}

@media (--MEDIUMSCREEN) {
  
  .cookies-confirm-settings {
  }
}

@media (--WIDESCREEN) {
  
  .cookies-confirm-settings {
  }
}

/* cookies confirm settings end */

/* cookies confirm settings item */

.cookies-confirm-settings-item {
  border-bottom: 1px solid #000;
  
  &:last-child {
    border: 0;
  }
  
  &__header {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    align-items: center;
    gap: 0.5rem 0.75rem;
    padding: 1rem 0;
    justify-content: space-between;
  
    ^&:first-child & {
      padding-top: 0;
    }
  }
  
  &__headline {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    font-size: 0.875rem;
    
    &--opener {
      cursor: pointer;
  
      &:before {
        width: 0.57em;
        height: 0.57em;
        border-left: 0.125rem solid #000;
        border-bottom: 0.125rem solid #000;
        content: '';
        flex: none;
        margin: 0.2em 0 0 0.125rem;
        transform: rotate(-45deg);
        transition: 0.25s linear;
    
        ^^^&.is-active & {
          margin-top: 0.5em;
          transform: rotate(135deg);
        }
      }
    }
  }
  
  &__count {
    min-width: 1.75em;
    height: 1.75em;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex: none;
    background: #e0e0e0;
    border-radius: 50%;
    font-size: 0.86em;
  }
  
  &__entry {
     flex: none;
  }
  
  &__desc {
    grid-column: 1/-1;
    font-size: 0.875rem;
    
    @nest :disabled ~ & {
      pointer-events: auto;
      opacity: 1;
    }
  
    p,
    ul, ol,
    th, td {
      font-size: 1em;
    }
  
    > *:last-child {
      margin-bottom: 0rem;
    }
  }
  
  &__content {
    display: none;
    padding: 0rem 0rem 1rem 0rem;
  }
}

@media (--SMALLSCREEN) {
  
  .cookies-confirm-settings-item {
  }
}

@media (--MEDIUMSCREEN) {
  
  .cookies-confirm-settings-item {
  }
}

@media (--WIDESCREEN) {
  
  .cookies-confirm-settings-item {
  }
}

/* cookies confirm settings item end */

/* cookies confirm records */

.cookies-confirm-records {
  
  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 1rem;
  }
}

@media (--SMALLSCREEN) {
  
  .cookies-confirm-records {
  }
}

@media (--MEDIUMSCREEN) {
  
  .cookies-confirm-records {
  }
}

@media (--WIDESCREEN) {
  
  .cookies-confirm-records {
  }
}

/* cookies confirm records end */

/* cookies confirm records item */

.cookies-confirm-records-item {
  font-size: 0.75rem;
  
  &__headline {
    display: block;
  }
  
  &__desc {
  
    p,
    ul, ol,
    th, td {
      font-size: 1em;
    }
    
    > *:last-child {
      margin-bottom: 0rem;
    }
  }
}

@media (--SMALLSCREEN) {
  
  .cookies-confirm-records-item {
  }
}

@media (--MEDIUMSCREEN) {
  
  .cookies-confirm-records-item {
  }
}

@media (--WIDESCREEN) {
  
  .cookies-confirm-records-item {
  }
}

/* cookies confirm records item end */

/* cookies confirm buttons */

.cookies-confirm-buttons {
  position: sticky;
  bottom: 0;
  padding: 1rem 0;
  background: white;
  text-align: center;
  
  &__content {
    width: 100%;
    display: inline-grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 0.625rem;
  }
  
  &__button {
    margin: 0;
    
    &--allow {
    }
  }
}

@media (--SMALLSCREEN) {
  
  .cookies-confirm-buttons {
    
    &__content {
      width: auto;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}

@media (--MEDIUMSCREEN) {
  
  .cookies-confirm-buttons {
  }
}

@media (--WIDESCREEN) {
  
  .cookies-confirm-buttons {
  }
}

/* cookies confirm buttons end */