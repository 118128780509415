@charset "utf-8";

/* LAYOUT */

/* page wrapper */

.page-wrapper {
  width: 100%;
  min-height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto 1fr auto;
  gap: 0rem;
}

/* page wrapper end */

/* page header */

.page-header {
  position: relative;
  z-index: 200;
  margin-bottom: 1.25rem;
}

@media (--SMALLSCREEN) {
  
  .page-header {
  }
}

@media (--MEDIUMSCREEN) {
  
  .page-header {
  }
}

@media (--WIDESCREEN) {
  
  .page-header {
    margin-bottom: 0rem;
  }
}

/* page header generally end */

/* page content */

.page-content {
}

/* page content end */

/* page footer */

.page-footer {
}

@media (--SMALLSCREEN) {
  
  .page-footer {
  }
}

@media (--MEDIUMSCREEN) {
  
  .page-footer {
  }
}

@media (--WIDESCREEN) {
  
  .page-footer {
  }
}

/* page footer end */