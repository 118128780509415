@charset "utf-8";

/* UŽIVATELSKÝ ÚČET PO PŘIHLÁŠENÍ */

/* user dashboard page */

.user-dashboard-page {
}

@media (--SMALLSCREEN) {
  
  .user-dashboard-page {
  }
}

@media (--MEDIUMSCREEN) {
  
  .user-dashboard-page {
  }
}

@media (--WIDESCREEN) {
  
  .user-dashboard-page {
  }
}

/* user dashboard page end */

/* user dashboard menu */

.user-dashboard-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  
  &__item {
    font-weight: bold;
    padding: 0.938em 2.813em 0.938em 1.25em;
    border: 1px solid var(--border-color-basic);
    text-decoration: none;
    background: #efefef;
    transition: 0.3s;
    position: relative;
    
    &:hover,
    &.is-active {
      background: #fff;
    }
    
    &:after{
      border-right: 0.125rem solid var(--color-basic);
      border-bottom: 0.125rem solid var(--color-basic);
      width: 0.625em;
      height: 0.625em;
      content: '';
      display: block;
      transform: rotate(-45deg);
      position: absolute;
      right: 1.375em;
      top: calc(50% - 0.313rem);
    }
  }
}

@media (--SMALLSCREEN) {
  
  .user-dashboard-menu {
  }
}

@media (--MEDIUMSCREEN) {
  
  .user-dashboard-menu {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media (--WIDESCREEN) {
  
  .user-dashboard-menu {
  }
}

/* user dashboard menu end */

/* user dashboard section */

.user-dashboard-section {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 1rem;
  
  &__headline {
    width: 100%;
    grid-column: 1/-1;
    margin: 0rem 0rem 0rem 0rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--border-color-basic);
    
    &:empty {
      display: none;
    }
  }
  
  &__block {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 1rem;
    
    &--full {
      grid-column: 1/-1;
    }
  }
}

@media (--SMALLSCREEN) {
  
  .user-dashboard-section {
  }
}

@media (--MEDIUMSCREEN) {
  
  .user-dashboard-section {
  }
}

@media (--WIDESCREEN) {
  
  .user-dashboard-section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

/* user dashboard section end */

/* user dashboard block */

.user-dashboard-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0rem;
  
  &__headline {
    width: 100%;
    min-height: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #efefef;
    margin: 0rem 0rem 0rem 0rem;
    padding: 0.875rem 1.25rem;
    font-size: 1rem;
  }
  
  &__content {
    flex-grow: 1;
    background: #f8f8f8;
    padding: 1.25rem 1.25rem;
  }
}

@media (--SMALLSCREEN) {
  
  .user-dashboard-block {
  }
}

@media (--MEDIUMSCREEN) {
  
  .user-dashboard-block {
    
    &__headline {
      min-height: 4rem;
    }
  }
}

@media (--WIDESCREEN) {
  
  .user-dashboard-block {
  }
}

/* user dashboard block end */

/* user dashboard table */

.user-dashboard-table {
  width: 100%;
  margin: 0rem 0rem 0rem 0rem;
  
  &__desc {
  }
  
  &__value {
    text-align: right;
    word-break: break-word;
  }
}

@media (--SMALLSCREEN) {
  
  .user-dashboard-table {
  }
}

@media (--MEDIUMSCREEN) {
  
  .user-dashboard-table {
  }
}

@media (--WIDESCREEN) {
  
  .user-dashboard-table {
  }
}

/* user dashboard table end */

/* user dashboard handles */

.user-dashboard-handles {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 1rem;
  
  &__button {
    width: 100%;
    margin: 0rem 0rem 0rem 0rem;
  }
}

@media (--SMALLSCREEN) {
  
  .user-dashboard-handles {
  
    &__button {
      width: auto;
      margin-left: auto;
    }
  }
}

@media (--MEDIUMSCREEN) {
  
  .user-dashboard-handles {
  }
}

@media (--WIDESCREEN) {
  
  .user-dashboard-handles {
  }
}

/* user dashboard handles end */

/* user dashboard order list */

.user-dashboard-order-list {
  border-top: 1px solid var(--border-color-basic);
}

@media (--SMALLSCREEN) {
  
  .user-dashboard-order-list {
  }
}

@media (--MEDIUMSCREEN) {
  
  .user-dashboard-order-list {
  }
}

@media (--WIDESCREEN) {
  
  .user-dashboard-order-list {
    border: 0rem;
  }
}

/* user dashboard order list end */

/* user dashboard order item */

.user-dashboard-order-item {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem 0.75rem;
  border-bottom: 1px solid var(--border-color-basic);
  padding: 0.875rem 0rem;
  
  &--head {
    display: none;
  }
  
  &__block {
  
    &--date {
      font-weight: bold;
      white-space: nowrap;
    }
    
    &--status {
      text-align: right;
    }
  
    &--symbol {
    }
  
    &--price {
      text-align: right;
    }
  
    &--price-value {
      white-space: nowrap;
      font-weight: bold;
    }
  
    &--invoice {
      grid-column: 1/-1;
    }
  }
}

@media (--SMALLSCREEN) {
  
  .user-dashboard-order-item {
  }
}

@media (--MEDIUMSCREEN) {
  
  .user-dashboard-order-item {
  }
}

@media (--WIDESCREEN) {
  
  .user-dashboard-order-item {
    grid-template-columns: minmax(0, 0.6fr) repeat(4, minmax(0, 1fr));
  
    &--head {
      display: grid;
      font-weight: bold;
      padding-top: 0rem;
    }
  
    &__block {
  
      &--status {
        text-align: center;
      }
      
      &--symbol {
        text-align: center;
      }
      
      &--price {
        text-align: center;
      }
  
      &--invoice {
        text-align: right;
        grid-column: auto;
      }
    }
  }
}

/* user dashboard order item end */