@charset "utf-8";

/* UTILITY A HELPERY */

/* widths */

.width-page {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--width-page) !important;
}

.width-page-complet {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--width-page-complet) !important;
}

.width-middle {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--width-middle) !important;
}

/* widths end */

/* gaps */

.gap-page {
  margin-bottom: var(--gap-page) !important;
}

.gap-block {
  margin-bottom: var(--gap-block) !important;
}

.gap-block-spaced {
  padding-top: var(--gap-block) !important;
  padding-bottom: var(--gap-block) !important;
}

.gap-block-spaced-top {
  padding-top: var(--gap-block) !important;
}

.gap-block-spaced-bottom {
  padding-bottom: var(--gap-block) !important;
}

.gap-element {
  margin-bottom: var(--gap-element) !important;
}

.gap-element-spaced {
  padding-top: var(--gap-element) !important;
  padding-bottom: var(--gap-element) !important;
}

.gap-element-spaced-top {
  padding-top: var(--gap-element) !important;
}

.gap-element-spaced-bottom {
  padding-bottom: var(--gap-element) !important;
}

.gap-element-small {
  margin-bottom: var(--gap-element-small) !important;
}

.gap-element-small-spaced {
  padding-top: var(--gap-element-small) !important;
  padding-bottom: var(--gap-element-small) !important;
}

.gap-element-small-spaced-top {
  padding-top: var(--gap-element-small) !important;
}

.gap-element-small-spaced-bottom {
  padding-bottom: var(--gap-element-small) !important;
}

.gap-text {
  margin-bottom: var(--gap-text) !important;
}

.gap-zero {
  margin-bottom: 0 !important;
}

/* gaps end */

/* fonts */

.font-size-basic {
  font-size: var(--font-size-basic) !important;
}

.font-size-small {
  font-size: var(--font-size-small) !important;
}

.font-size-h1 {
  font-size: var(--font-size-h1) !important;
}

.font-size-h2 {
  font-size: var(--font-size-h2) !important;
}

.font-size-h3 {
  font-size: var(--font-size-h3) !important;
}

.font-size-h4 {
  font-size: var(--font-size-h4) !important;
}

.font-size-h5 {
  font-size: var(--font-size-h5) !important;
}

.font-size-h6 {
  font-size: var(--font-size-h6) !important;
}

.line-height-basic {
  line-height: var(--line-height-basic) !important;
}

/* fonts end */

/* colors */

.color-basic {
  color: var(--color-basic) !important;
}

/* colors end */

/* aligns */

.align-left {
  margin-left: 0 !important;
  margin-right: auto !important;
}

.align-center {
  margin-left: auto !important;
  margin-right: auto !important;
}

.align-right {
  margin-left: auto !important;
  margin-right: 0 !important;
}

.align-content-left {
  text-align: left !important;
}

.align-content-center {
  text-align: center !important;
}

.align-content-right {
  text-align: right !important;
}

/* aligns end */