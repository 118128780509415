@charset "utf-8";

/* DASHBOARDOVÉ KOMPONENTY */

/* dashboard */

.dashboard {
  width: 100%;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: flex-end;
  margin: 0rem 0rem 0rem 0rem;
  font-size: 0.875rem;
  gap: 0.5rem;
}

@media (--SMALLSCREEN) {
  
  .dashboard {
  }
}

@media (--MEDIUMSCREEN) {
  
  .dashboard {
    font-size: 1rem;
  }
}

@media (--WIDESCREEN) {
  
  .dashboard {
    width: auto;
    margin-left: auto;
  }
}

/* dashboard end */

/* language select */

.language-select {
  display: flex;
  gap: 0.25rem;
  margin: 0rem 0rem 0rem 0rem;
  text-align: center;
  
  &__item {
    flex: none;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.5rem 0.25rem;
    text-decoration: none;
    min-width: 2.25rem;
    background: #e0e0e0;
    margin: 0rem 0rem 0rem 0rem;
    
    &:hover,
    &.is-active {
      background-color: #cfcfcf;
    }
  }
  
  &__item-desc {
  }
}

@media (--SMALLSCREEN) {
  
  .language-select {
  }
}

@media (--MEDIUMSCREEN) {
  
  .language-select {
    
    &__item {
      padding: 0.75rem 0.75rem;
    }
  }
}

@media (--WIDESCREEN) {
  
  .language-select {
  }
}

/* language select end */

/* currency select */

.currency-select {
  display: flex;
  gap: 0.25rem;
  margin: 0rem 0rem 0rem 0rem;
  
  &__item {
    flex: none;
    cursor: pointer;
    font-weight: bold;
    padding: 0.5rem 0.25rem;
    text-decoration: none;
    min-width: 2.25rem;
    background: #e0e0e0;
    margin: 0rem 0rem 0rem 0rem;
    
    &:hover,
    &.is-active {
      background-color: #cfcfcf;
    }
  }
  
  &__item-symbol {
    display:none;
  }
  
  &__item-desc {
  }
  
  &__form {
    display: none;
  }
}

@media (--SMALLSCREEN) {
  
  .currency-select {
  }
}

@media (--MEDIUMSCREEN) {
  
  .currency-select {
    
    &__item {
      padding: 0.75rem 0.75rem;
    }
  }
}

@media (--WIDESCREEN) {
  
  .currency-select {
  }
}

/* currency select end */

/* basket preview */

.basket-preview {
  font-weight: bold;
  padding: 0.5rem 0rem;
  min-width: 2.25rem;
  background: url(../images/style/icons/basket.svg) no-repeat center center / 1.625rem auto #E0E0E0;
  margin: 0rem 0rem 0rem 0rem;
  text-decoration: none;
  
  &:hover {
    background-color: #cfcfcf;
  }
  
  &:after {
    content:'\00a0';
  }
  
  &__desc {
    display: none;
  }
}

@media (--SMALLSCREEN) {
  
  .basket-preview {
  }
}

@media (--MEDIUMSCREEN) {
  
  .basket-preview {
    padding: 0.75rem 1rem 0.75rem 3.5rem;
    background-position: 1rem center;
    
    &:after {
      content:'';
    }
    
    &__desc {
      display: block;
    }
  }
}

@media (--WIDESCREEN) {
  
  .basket-preview {
  }
}

/* basket preview end */

/* login preview */

.login-preview {
  display: flex;
  gap: 0.25rem;
  margin: 0rem auto 0rem 0rem;
  
  &__item {
    flex: none;
    font-weight: bold;
    padding: 0.5rem 0.25rem 0.5rem 0.25rem;
    text-decoration: none;
    min-width: 2.25rem;
    cursor: pointer;
    background: url(../images/style/icons/user.svg) no-repeat center center / 1.25rem auto #E0E0E0;
  
    &:hover {
      background-color: #cfcfcf;
    }
    
    &:after {
      content:'\00a0';
    }
    
    &--logout {
      display: none;
    }
  }
  
  &__item-desc {
    display:none;
  }
}

@media (--SMALLSCREEN) {
  
  .login-preview {
  }
}

@media (--MEDIUMSCREEN) {
  
  .login-preview {
    
    &__item {
      padding: 0.75rem 0.75rem 0.75rem 2.5rem;
      background-position: 0.75rem center;
      
      &:after {
        content: '';
      }
    }
  
    &__item-desc {
      display: block;
    }
  }
}

@media (--WIDESCREEN) {
  
  .login-preview {
  }
}

/* login preview end */

/* searchbox opener */

.searchbox-opener {
  min-width: 2.25rem;
  padding: 0.5rem 0.25rem;
  cursor: pointer;
  background: url(../images/style/icons/search.svg) no-repeat center center / 1.313rem auto #E0E0E0;
  margin: 0rem 0rem 0rem 0rem;
  
  &:hover {
    background-color: #cfcfcf;
  }
  
  &:after {
    content:'\00a0';
  }
}

@media (--SMALLSCREEN) {
  
  .searchbox-opener {
  }
}

@media (--MEDIUMSCREEN) {
  
  .searchbox-opener {
    padding: 0.75rem 0.75rem;
    min-width: 3rem;
  }
}

@media (--WIDESCREEN) {
  
  .searchbox-opener {
  }
}

/* searchbox opener */